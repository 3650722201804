import React from 'react';
import { Breadcrumb, Radio, Label, Badge, Dropdown, Button, Spinner } from 'flowbite-react';
import { dataGeneratedTests } from './../../data/dataGeneratedTests';
import EdInput from './../../components/edInput';


const PageGenerateTestDemo = () => {
    const [selectedTestData, setSelectedTestData] = React.useState(undefined);
    const [testNumber, setTestNumber] = React.useState(undefined);
    const [testSubject, setTestSubject] = React.useState(undefined);
    const isBeforeProcess = (selectedTestData?.length === undefined)
    const isProcessing = (selectedTestData?.length === 0)
    const isAfterProcess = (selectedTestData?.length > 0)


    const handleGenerate = (e) => {
        e.preventDefault();
        setSelectedTestData([])
        setTimeout(() => setSelectedTestData(dataGeneratedTests.slice(0, testNumber)), 3000)
    }

    const showTestGenerator = () =>
        <div className="max-w-4xl items-center justify-start">
            <div className="flex items-start justify-start w-full h-full space-x-4 px-8 ">
                <EdInput
                    type="edSampleTestSubject"
                    name="testSubject"
                    label="Select Subject"
                    defaultValue={testSubject}
                    autoComplete={false}
                    isRequired={true}
                    onChange={(e) => setTestSubject(e.target.value)}
                    className="w-64"
                />
                <EdInput
                    type="edSampleTestNumber"
                    name="testNumber"
                    label="Number of Questions"
                    defaultValue={testNumber}
                    autoComplete={false}
                    isRequired={true}
                    onChange={(e) => setTestNumber(e.target.value)}
                    className="w-64"
                />

                <div className="my-5">
                    <Button
                        size="sm"
                        onClick={handleGenerate}
                        className="button-sky"
                        disabled={testNumber === undefined || testSubject === undefined}
                    >
                        Generate Test
                    </Button>
                </div>
            </div>
        </div>

    const showTestResult = () =>
        <div className="max-w-2xl items-start justify-start min-h-screen" id="printableResult">
            {selectedTestData?.map((test, testIndex) =>
                <fieldset className="flex flex-col gap-4 mb-16" key={testIndex}>
                    <legend className="flex items-top gap-2 mb-4">
                        {test.order}. {test.question}
                        <Badge color={test.difficulty === "Easy" ? "green" : test.difficulty === "Medium" ? "yellow" : "red"}>{test.difficulty}</Badge>
                    </legend>

                    {test.answers.map((answerItem, answerIndex) =>
                        <div className="flex ml-4 gap-2" key={answerIndex}>
                            <Radio
                                defaultChecked={answerItem.correct}
                                id={`radio-${testIndex}${answerIndex}`}
                                name={`radio-group-${testIndex}`}
                                value={answerItem.correct}
                                className="cursor-pointer"
                            />
                            <Label htmlFor={`radio-${testIndex}${answerIndex}`} className="cursor-pointer">
                                {answerItem.answer}
                            </Label>
                        </div>
                    )}
                </fieldset>
            )}
        </div>

    const handlePrint = () => {
        const content = document.getElementById("printableResult");
        const pri = document.getElementById("printableFrame").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const handleExportJson = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(selectedTestData)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        const now = new Date();
        link.download = `${now.getFullYear()}${now.getMonth().toString().padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}-${now.getHours().toString().padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}-${testSubject?.toLowerCase().replace(" ", "-")}.json`;
        link.click();
    }


    return <React.Fragment>
        <main className="dashboard p-8 pb-24">
            <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
                <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Generate Test Demo</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="text-3xl mb-0">Generate Test Demo</h1>
            <p className="text-lg mb-6">Generate test for a course.</p>

            <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">

                {/* Table Toolbar */}
                <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4 border-b border-gray-100 dark:border-gray-700">
                    {/* Left Side */}
                    <div></div>
                    {/* Right Side */}
                    <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                        <Dropdown
                            label="Export"
                            size="sm"
                            className='button-sky'
                            disabled={!isAfterProcess}>
                            {[
                                { name: "PDF", icon: "fa fa-file-pdf", onClick: handlePrint },
                                { name: "LaTex", icon: "fa fa-file-code" },
                                { name: "Word (docx)", icon: "fa fa-file-word" },
                                { name: "JSON", icon: "fa fa-file-code", onClick: handleExportJson },
                            ].map((item, index) =>
                                <Dropdown.Item
                                    key={index}
                                    onClick={item.onClick}
                                    disabled={!isAfterProcess || !item?.onClick}>
                                    <i className={item.icon + ` ${(!isAfterProcess || !item?.onClick) ? "text-gray-400" : "text-gray-700 dark:text-gray-100"}`}></i>
                                    <span htmlFor={item.id} className={`ml-2 text-sm font-medium ${(!isAfterProcess || !item?.onClick) ? "text-gray-400" : "text-gray-700 dark:text-gray-100"}`} >
                                        {item.name}
                                    </span>
                                </Dropdown.Item>
                            )}
                        </Dropdown>
                        <Button
                            size="sm"
                            color='light'
                            onClick={() => handlePrint()}
                            disabled={!isAfterProcess}
                        >
                            Print
                            <i className="ml-2 fas fa-print"></i>
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-start w-full h-full p-8 min-h-screen">
                    {isBeforeProcess && showTestGenerator()}
                    {isProcessing && <Spinner size="xl" />}
                    {isAfterProcess && showTestResult()}
                </div>
            </div>

            <iframe id="printableFrame" style={{ height: "0px", width: "0px", position: "absolute" }} title="print"></iframe>
        </main>
    </React.Fragment>
}

export default PageGenerateTestDemo