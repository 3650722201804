import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PagePricing = () =>
    <main>
        <EdHelmet title="Tarifs" description="Notre objectif est de fournir à nos utilisateurs une expérience fluide et bénéfique. Comme nous affinons actuellement nos services pour mieux répondre à vos besoins, nous sommes heureux de proposer un essai gratuit de notre plateforme." />
        <section>
            <h1>Tarifs</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Notre objectif est de fournir à nos utilisateurs une expérience fluide et bénéfique. Comme nous affinons actuellement nos services pour mieux répondre à vos besoins, nous sommes heureux de proposer un
                <a href="/register" target="_blank" rel="noopener noreferrer"> Essai Gratuit </a>
                de notre plateforme.
            </p>
            <hr />
        </section>
    </main>

export default PagePricing;