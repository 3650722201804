import Swal from "sweetalert2";

const getDefaultTheme = () => window.localStorage.getItem('theme')

const showError = (title, text) =>
    Swal.fire({
        title,
        text,
        icon: 'error',
        background: getDefaultTheme() === 'dark' ? '#2d3748' : '#fff',
        color: getDefaultTheme() === 'dark' ? '#fff' : '#2d3748',
        customClass: {
            confirmButton: 'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-100 border-0 focus:outline-none focus:ring-0',
        }
    });


const showSuccess = (title, text) =>
    Swal.fire({
        title,
        text,
        icon: 'success',
        background: getDefaultTheme() === 'dark' ? '#2d3748' : '#fff',
        color: getDefaultTheme() === 'dark' ? '#fff' : '#2d3748',
        customClass: {
            confirmButton: 'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-100 border-0 focus:outline-none focus:ring-0',
        }
    });

const showWarning = ({ title, text, confirmButtonText, cancelButtonText }) =>
    Swal.fire({
        title,
        text,
        icon: 'warning',
        background: getDefaultTheme() === 'dark' ? '#2d3748' : '#fff',
        color: getDefaultTheme() === 'dark' ? '#fff' : '#2d3748',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#6b7280',
        confirmButtonText,
        cancelButtonText,
    });


const showNotImplemented = () => {
    Swal.fire({
        title: 'Not Implemented',
        text: 'This feature is not implemented yet.',
        icon: 'info',
        background: getDefaultTheme() === 'dark' ? '#2d3748' : '#fff',
        color: getDefaultTheme() === 'dark' ? '#fff' : '#2d3748',
        customClass: {
            confirmButton: 'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-gray-100 border-0 focus:outline-none focus:ring-0',
        }
    });
}


export {
    showError,
    showSuccess,
    showNotImplemented,
    showWarning,
};
