import React from "react";
import { redirect, Navigate } from "react-router-dom";
import PageHome from './pageHome';
import PageAbout from './pageAbout';
import PageError from './pageError';
import LayoutMain from './layoutMain';
import PageContact from './pageContact';
import PageSolutions from './pageSolutions';
import PagePricing from './pagePricing';
import PageBlog from './pageBlog';
import PageBlogPost from './pageBlogPost';
import PageRegister from './pageRegister';
import PageSignIn from './pageSignIn';
import PageBlogTag from './pageBlogTag';
import PageDocumentation from './pageDocumentation';
import PageHelp from './pageHelp';
import PageTerms from './pageTerms';
import PagePrivacy from './pagePrivacy';
import PageLicensing from './pageLicensing';
import PagePolicy from './pagePolicy';
import PageCareers from "./pageCareers";

const RouterFr = {
    path: "/fr",
    element: <LayoutMain />,
    errorElement: <PageError />,
    children: [
        { path: "/fr/", element: <PageHome /> },
        { path: "/fr/solutions", element: <PageSolutions /> },
        { path: "/fr/solution", element: <Navigate to="/fr/solutions" replace /> },
        { path: "/fr/pricing", element: <PagePricing /> },
        { path: "/fr/blog", element: <PageBlog /> },
        { path: "/fr/blog/:slug", element: <PageBlogPost /> },
        { path: "/fr/blog/tag/:tag", element: <PageBlogTag /> },
        { path: "/fr/about", element: <PageAbout /> },
        { path: "/fr/contact", element: <PageContact /> },
        { path: "/fr/careers", element: <PageCareers /> },
        { path: "/fr/career", element: <Navigate to="/careers" replace /> },
        { path: "/fr/documentation", element: <PageDocumentation /> },
        { path: "/fr/help", element: <PageHelp /> },
        { path: "/fr/terms", element: <PageTerms /> },
        { path: "/fr/term", element: <Navigate to="/fr/terms" replace /> },
        { path: "/fr/privacy", element: <PagePrivacy /> },
        { path: "/fr/licensing", element: <PageLicensing /> },
        { path: "/fr/policy", element: <PagePolicy /> },
        { path: "/fr/register", element: <PageRegister /> },
        { path: "/fr/signin", element: <PageSignIn /> },
        { path: "/fr/login", element: <Navigate to="/signin" replace /> },
        { path: "*", action: () => redirect("/fr/error") },
    ],
}

export default RouterFr;
