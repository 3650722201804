import React from 'react';
import EdHelmet from './../../components/edHelmet';

const PageContact = () =>
    <main>
        <EdHelmet title="Contact Us" description="Please feel free to contact us on social media or by EdPLATO's email if you have any questions about EdPLATO. Join us in revolutionizing education!" />
        <section>
            <h1>Contact us</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Please feel free to contact us on social media or by
                <a className="mx-1" href="mailto:info@edplato.com?subject=Hello from EdPLATO" target="_blank" rel="noopener noreferrer">info@edplato.com</a>
                if you have any questions about EdPLATO.
                <br />
                Join us in revolutionizing education!
            </p>
            <hr />
        </section>

    </main>

export default PageContact;