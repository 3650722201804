import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageSolutions = () =>
    <main>
        <EdHelmet title="Solutions" description="Accelerating Learning with AI-Driven Solutions" />
        <section>
            <h1>Solutions</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Accelerating Learning with AI-Driven <a href="/solutions/">Solutions</a>
            </p>
            <hr />
        </section>
        <section>
            <h2>Dynamic AI-Driven Question Generation:</h2>
            <ul className="pl-4 mb-8 list-disc list-inside text-gray-600 dark:text-gray-400">
                <li>Generate questions rapidly and effortlessly with AI</li>
                <li>Diverse question types to enhance engagement (MCQs, T/F and many more)</li>
                <li>Easy question and option editing</li>
                <li>Export options in PDF, Word, or Excel format</li>
                <li>Seamless integration with existing Learning Management Systems (LMS) or Enterprise Resource Planning (ERP) platforms</li>
                <li>Integration of multiple content sources</li>

            </ul>
        </section>

        <section>
            <h2>Versatile Question Types supported:</h2>
            <p>Whether it's multiple choice, true or false, fill in the blanks, short answer, essay, numerical,, case study or scenario-based questions, our AI-powered platform can generate them all.</p>
            <ul>
                <li><b>Multiple Choice Questions (MCQ): </b>Selecting the correct option from a list of choices</li>
                <li><b>True or False Questions (T/F): </b>Determining the accuracy of a given statement</li>
                <li><b>Fill in the Blanks: </b>Completing a sentence or passage by filling in the missing words</li>
                <li><b>Matching Questions: </b>Matching items from two columns based on their relationships</li>
                <li><b>Short Answer Questions: </b>Providing a brief, concise answer to a specific question</li>
                <li><b>Numerical Questions: </b>Solving mathematical problems and providing numerical answers</li>
                <li><b>Ordering or Sequencing Questions: </b>Arranging items or events in a specific order based on given criteria</li>
                <li><b>Case Study Questions: </b>Analyzing a case scenario and answering questions based on the provided information</li>
                <li><b>Opinion-Based Questions: </b>Expressing personal viewpoints or arguments on a given topic</li>
                <li><b>Scenario-Based Questions: </b>Responding to questions based on hypothetical situations or scenarios</li>
            </ul>
        </section>

        <section>
            <h2>AI-Enabled Case Study Writing:</h2>
            <p>Case writing is an essential learning method, particularly for business school students. Access to valuable cases from prestigious case publishing institutions is often limited for certain schools and instructors due to various reasons, including cost constraints. At EdPLATO, we believe in the accessibility of information for everyone.</p>
            <p>With our AI-powered platform and an extensive database of exemplary case studies, we aim to bridge this gap and enable instructors to write compelling cases at an accelerated pace. By leveraging the power of AI, we provide educators with a rich pool of case study examples, facilitating the creation of high-quality cases that engage and inspire students.</p>
            <ul>
                <li><b>Efficient Case Generation: </b>Generate professional case studies in a fraction of the time compared to manual creation.</li>
                <li><b>Streamlined Process: </b>Streamline the case writing process by eliminating the need for third-party involvement.</li>
                <li><b>Customization and Enhancement: </b>Validate, edit, and enhance the generated case study to meet your specific requirements.</li>
                <li><b>Free Access and Benefits: </b>Enjoy the benefits of this invaluable tool, currently available for free for a limited time.</li>
            </ul>
        </section>

        <section>
            <p className='mb-4 text-gray-600 dark:text-gray-400 italic'>
                <a className="mr-1" href="/contact">Book an appointment</a> with our experts to capture essential information
            </p>
        </section>
    </main>


export default PageSolutions;