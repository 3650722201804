import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageSolutions = () =>
    <main>
        <EdHelmet title="Solutions" description="Accelerate learning with AI-powered solutions." />
        <section>
            <h1>Solutions</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Accélérer l'apprentissage avec des <a href="/solutions/">solutions</a> basées sur l'IA.
            </p>
            <hr />
        </section>
        <section>
            <h2>Génération dynamique de questions basée sur l'IA :</h2>
            <ul className="pl-4 mb-8 list-disc list-inside text-gray-600 dark:text-gray-400">
                <li>Générez aisément des questions avec l'assistance rapide de l'IA.</li>
                <li>Explorez diverses catégories de questions pour une expérience pédagogique enrichissante, incluant QCM, Vrai/Faux et bien d'autres.</li>
                <li>Personnalisation simplifiée : ajustez les questions et les choix de réponses selon vos besoins.</li>
                <li>Exportez vos créations au format PDF, Word ou Excel en toute simplicité.</li>
                <li>Intégrez harmonieusement notre solution avec vos systèmes de gestion de l'apprentissage (LMS) actuels et plateformes de planification d'entreprise (ERP).</li>
                <li>Agrégation de multiples sources de contenu pour une approche intégrée.</li>

            </ul>
        </section>

        <section>
            <h2>Types polyvalents de questions pris en charge :</h2>
            <p>Qu'il s'agisse de questions à choix multiples, vrai ou faux, compléter les blancs, réponse courte, essai, numérique, étude de cas ou des questions basées sur des scénarios, notre plateforme alimentée par l'IA peut les générer toutes.</p>
            <ul>
                <li><b>Questions à choix multiples (QCM) :</b> Sélectionner l'option correcte parmi une liste de choix.</li>
                <li><b>Questions Vrai ou Faux (T/F) :</b> Déterminer l'exactitude d'une déclaration donnée.</li>
                <li><b>Compléter les blancs :</b> Compléter une phrase ou un passage en remplissant les mots manquants.</li>
                <li><b>Questions d'appariement :</b> Mettre en correspondance des éléments de deux colonnes en fonction de leurs relations.</li>
                <li><b>Questions à réponse courte :</b> Fournir une réponse brève et concise à une question spécifique.</li>
                <li><b>Questions numériques :</b> Résoudre des problèmes mathématiques et fournir des réponses numériques.</li>
                <li><b>Questions d'ordonnancement :</b> Organiser des éléments ou des événements dans un ordre spécifique en fonction de critères donnés.</li>
                <li><b>Questions d'étude de cas :</b> Analyser un scénario et répondre aux questions en fonction des informations fournies.</li>
                <li><b>Questions basées sur l'opinion :</b> Exprimer des points de vue personnels ou des arguments sur un sujet donné.</li>
                <li><b>Questions basées sur des scénarios :</b> Répondre aux questions en se basant sur des situations ou des scénarios hypothétiques.</li>
            </ul>
        </section>

        <section>
            <h2>Types de questions polyvalents pris en charge :</h2>
            <p>Qu'il s'agisse de questions à choix multiples, de questions de type vrai ou faux, de complétion de blancs, de réponses courtes, d'essais, de questions numériques, d'études de cas ou de questions basées sur des scénarios, notre plateforme alimentée par l'IA est capable de toutes les générer.</p>
            <ul>
                <li><b>Questions à choix multiples (QCM) :</b> Sélectionner l'option correcte parmi une liste de choix.</li>
                <li><b>Questions Vrai ou Faux (T/F) :</b> Déterminer l'exactitude d'une déclaration donnée.</li>
                <li><b>Compléter les blancs :</b> Compléter une phrase ou un passage en remplissant les mots manquants.</li>
                <li><b>Questions d'appariement :</b> Mettre en correspondance des éléments de deux colonnes en fonction de leurs relations.</li>
                <li><b>Questions à réponse courte :</b> Fournir une réponse brève et concise à une question spécifique.</li>
                <li><b>Questions numériques :</b> Résoudre des problèmes mathématiques et fournir des réponses numériques.</li>
                <li><b>Questions d'ordonnancement :</b> Organiser des éléments ou des événements dans un ordre spécifique en fonction de critères donnés.</li>
                <li><b>Questions d'étude de cas :</b> Analyser un scénario et répondre aux questions en fonction des informations fournies.</li>
                <li><b>Questions basées sur l'opinion :</b> Exprimer des points de vue personnels ou des arguments sur un sujet donné.</li>
                <li><b>Questions basées sur des scénarios :</b> Répondre aux questions en se basant sur des situations ou des scénarios hypothétiques.</li>
            </ul>
        </section>

        <section>
            <p className='mb-4 text-gray-600 dark:text-gray-400 italic'>
                <a className="mr-1" href="/contact">Prenez rendez-vous</a> avec nos experts pour obtenir des informations essentielles
            </p>
        </section>
    </main>


export default PageSolutions;