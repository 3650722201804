import React from 'react';
import Lottie from "lottie-react";
import EdButton from './edButton';

const BlockHero = ({ title, description, ctaLabel, ctaLink, animationData }) =>
    <section className="container p-8 mx-auto flex flex-wrap ">
        <div className="flex items-center w-full lg:w-1/2">
            <div className="mb-8">
                <h1 className="text-4xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight xl:text-6xl xl:leading-tight dark:text-white ">
                    {title}
                </h1>
                <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl dark:text-gray-300">
                    {description}
                </p>

                <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row">
                    <EdButton color="sky" to={ctaLink} className="px-8 py-4 text-lg font-medium">{ctaLabel}</EdButton>
                </div>
            </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2">
            <Lottie animationData={animationData} loop={true} height={600} width={600} />
        </div>
    </section>

export default BlockHero;
