import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const data = [
    {
        month: "2022 Dec",
        "Assignment Performance": 24.3,
        "Test Performance": 12.9,
        "Adaptive Learning Engagement": 12,
    },
    {
        month: "2023 Jan",
        "Assignment Performance": 18.2,
        "Test Performance": 9.3,
        "Adaptive Learning Engagement": 13.4,
    },
    {
        month: "2023 Feb",
        "Assignment Performance": 22.8,
        "Test Performance": 31.4,
        "Adaptive Learning Engagement": 22.7,
    },
    {
        month: "2023 Mar",
        "Assignment Performance": 16.9,
        "Test Performance": 23.7,
        "Adaptive Learning Engagement": 12.1,
    },
    {
        month: "2023 Apr",
        "Assignment Performance": 11.5,
        "Test Performance": 29.1,
        "Adaptive Learning Engagement": 13.2,
    },
    {
        month: "2023 May",
        "Assignment Performance": 12.1,
        "Test Performance": 59.4,
        "Adaptive Learning Engagement": 13.9,
    },
    {
        month: "2023 Jun",
        "Assignment Performance": 18.1,
        "Test Performance": 35.1,
        "Adaptive Learning Engagement": 21.2,
    },
];


const BlockChart = () =>
    <ResponsiveContainer width="100%" height="100%" >
        <AreaChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="Adaptive Learning Engagement" stackId="1" stroke="#ffc658" fill="#ffc658" />
            <Area type="monotone" dataKey="Test Performance" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="Assignment Performance" stackId="1" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
    </ResponsiveContainer>

export default BlockChart;