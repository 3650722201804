import React from 'react';
import EdHelmet from '../../components/edHelmet';
import { dataOurCareers } from './../../data/dataOurCareers';

const PageCareers = () =>
    <main>
        <EdHelmet title="Carrières" description="Joignez-vous à nous! Êtes-vous prêt à faire partie de l'équipe dynamique qui façonne l'avenir de l'éducation grâce à la technologie? Rejoignez-nous et laissez une empreinte dans le monde de l'apprentissage! Postulez ici." />
        <section>
            <h1>Carrières</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Joignez-vous à nous! Êtes-vous prêt à faire partie de l'équipe dynamique qui façonne l'avenir de l'éducation grâce à la technologie? Rejoignez-nous et laissez une empreinte dans le monde de l'apprentissage! Postulez ici.
            </p>
            <hr />
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Opportunités de Stage</h2>
            <p>Chez EdPLATO, nous croyons en la promotion de la prochaine génération de talents. C'est pourquoi nous proposons des stages passionnants dans divers départements. Si vous êtes un apprenant passionné cherchant à débuter votre carrière dans le monde de la technologie éducative, nous vous encourageons à explorer nos programmes de stage.</p>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Pourquoi nous rejoindre?</h2>
            <p>Chez EdPLATO, nous croyons en la promotion de la prochaine génération de talents. C'est pourquoi nous offrons des stages passionnants dans divers départements. Si vous êtes un apprenant passionné cherchant à débuter votre carrière dans le monde de la technologie éducative, nous vous encourageons à explorer nos programmes de stage.</p>
            <ul>
                <li><b>Innover: </b>Soyez à la pointe de l'innovation éducative, travaillant sur des projets avant-gardistes qui façonnent l'avenir de l'apprentissage.</li>
                <li><b>Collaborer: </b>Collaborez avec une équipe d'experts dédiée à avoir un impact positif sur l'éducation dans le monde entier.</li>
                <li><b>Apprendre & Grandir: </b>L'apprentissage continu est dans notre ADN. Nous offrons des opportunités pour le développement personnel et professionnel.</li>
                <li><b>Impacter: </b>Votre travail ici aura un impact direct sur la manière dont les gens apprennent et enseignent, rendant l'éducation accessible et efficace pour tous.</li>
            </ul>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Postes disponibles</h2>
            <p>Prêt à faire une différence dans le monde de la technologie éducative? Postulez dès aujourd'hui!</p>
            <table className="w-full text-left border-collapse">
                <thead>
                    <tr>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Département</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Titre</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Lieu</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Postuler</th>
                    </tr>
                </thead>
                <tbody>
                    {dataOurCareers.map((item, index) => (
                        <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.department.fr}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.title.fr}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.location.fr}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">
                                <a
                                    className="select-none no-underline rounded-md text-white bg-sky-600 hover:text-white hover:bg-sky-500 text-sm py-2 px-4"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={"mailto:careers@edplato.com?subject=" + encodeURIComponent("Application for " + item.title.fr)}
                                >
                                    Postuler
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    </main>

export default PageCareers