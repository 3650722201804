import React from 'react';
import EdHelmet from '../../components/edHelmet';
import BlockTeamMember from './../../components/blockTeamMember';
import { dataOurTeam } from './../../data/dataOurTeam';

const PageAbout = () => {


    return <main>
        <EdHelmet title="About us" description="EdPLATO is a trailblazer in the realm of AI-powered learning and ed-tech. Rooted in Canada, EdPLATO is dedicated to revolutionizing education through innovative AI-driven ed-tech solutions that prioritize accessibility, knowledge-orientedness, and social impact." />
        <section>
            <h1>About us</h1>
            <p className="mb-8 text-lg lg:text-lg">
                <a href="/">EdPLATO</a> is a trailblazer in the realm of AI-powered learning and ed-tech. Rooted in Canada, EdPLATO is dedicated to revolutionizing education through innovative AI-driven ed-tech solutions that prioritize accessibility, knowledge-orientedness, and social impact.
                The name EdPLATO represents our commitment to "Education with Personalized Learning, Adaptive Testing, and Online AI." This embodies our core emphasis on personalized learning experiences, adaptive assessment strategies, and leveraging the power of AI to enhance educational outcomes.
            </p>
            <hr />
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Our Vision</h2>
            <p>Our mission is clear: to empower students from diverse backgrounds to unlock their full potential and achieve their academic and professional goals through the power of AI. Simultaneously, we strive to build a more equitable and sustainable world through our AI-driven educational initiatives. By harnessing the capabilities of AI and blending it with educational expertise, we aim to create transformative learning experiences that shape the future of education.</p>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Our Team</h2>
            <p></p>
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6">
                {dataOurTeam.map((member, index) => <BlockTeamMember key={index} lang="en" member={member} />)}
            </div>
        </section>
    </main>
}

export default PageAbout;