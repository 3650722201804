import React from 'react';
import { Helmet } from 'react-helmet';
import { getCurrentLang, isFrench } from '../helper/languageHelper';
import { isEmpty } from '../helper/stringHelper';

const getTitle = (title) =>
    isEmpty(title)
        ? isFrench()
            ? "EdPLATO - Plateforme alimentée par l'IA qui transforme l'apprentissage"
            : "EdPLATO - AI-powered platform transforming learning"
        : title.trim() + " | EdPLATO"

const getDescription = (description) =>
    isEmpty(description)
        ? isFrench()
            ? "EdPLATO est une plateforme éducative avancée qui offre des cours générés par l'IA supervisés par des professeurs, des cas, des quiz pop, une notation par IA et d'autres outils d'évaluation."
            : "EdPLATO is an advanced educational platform that offers AI-generated professor-supervised courses, cases, pop quizzes, AI-grading and other evaluation tools."
        : description.trim()

const getImage = (image) =>
    isEmpty(image)
        ? "/logo.png"
        : image.trim()


const EdHelmet = ({ title, description, image, url }) =>
    <Helmet htmlAttributes={{ lang: getCurrentLang() }}>
        <meta name="description" content={getDescription(description)} />
        <title lang={getCurrentLang()}>{getTitle(title)}</title>

        {/* Open Graph / Facebook Meta Tags */}
        <meta property="og:title" content={getTitle(title)} />
        <meta property="og:description" content={getDescription(description)} />
        <meta property="og:image" content={getImage(image)} />
        <meta property="og:type" content="website" />
        {!isEmpty(url) && <meta property="og:url" content={url.trim()} />}

        {/* Twitter Twitter Meta Tags */}
        <meta name="twitter:title" content={getTitle(title)} />
        <meta name="twitter:description" content={getDescription(description)} />
        <meta name="twitter:image" content={getImage(image)} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edplato.com" />
        {!isEmpty(url) && <meta property="twitter:url" content={url.trim()} />}
    </Helmet>

export default EdHelmet