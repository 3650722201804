import React from 'react';
import EdHelmet from '../../components/edHelmet';
import { dataOurCareers } from './../../data/dataOurCareers';

const PageCareers = () =>
    <main>
        <EdHelmet title="Careers" description="Join Us! Are you ready to be a part of the dynamic team that's shaping the future of education through technology? Join us and make an impact with a legacy in the world of learning! Apply here." />
        <section>
            <h1>Careers</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Join Us! Are you ready to be a part of the dynamic team that's shaping the future of education through technology? Join us and make an impact with a legacy in the world of learning! Apply here.
            </p>
            <hr />
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Internship Opportunities</h2>
            <p>At EdPLATO, we believe in nurturing the next generation of talent. That's why we offer exciting internship opportunities across various departments. If you're a passionate learner looking to kickstart your career in the world of EdTech, we encourage you to explore our internship programs.</p>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Why Join?</h2>
            <p>At EdPLATO, we believe in nurturing the next generation of talent. That's why we offer exciting internship opportunities across various departments. If you're a passionate learner looking to kickstart your career in the world of EdTech, we encourage you to explore our internship programs.</p>
            <ul>
                <li><b>Innovate: </b>Be at the forefront of educational innovation, working on cutting-edge projects that shape the future of learning.</li>
                <li><b>Collaborate: </b>Collaborate with a team of experts who are dedicated to making a positive impact on education worldwide.</li>
                <li><b>Learn & Grow: </b>Continuous learning is in our DNA. We offer opportunities for personal and professional development.</li>
                <li><b>Impact: </b>Your work here will directly impact the way people learn and teach, making education accessible and effective for all.</li>
            </ul>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Current Openings</h2>
            <p>Ready to make a difference in the world of education technology? Apply today!</p>
            {/* // create a table with dataOurCareers */}
            <table className="w-full text-left border-collapse">
                <thead>
                    <tr>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Department</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Title</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Location</th>
                        <th className="py-4 px-6 bg-gray-100 font-bold uppercase text-sm text-gray-600 border-b border-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600">Apply</th>
                    </tr>
                </thead>
                <tbody>
                    {dataOurCareers.map((item, index) => (
                        <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.department.en}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.title.en}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">{item.location.en}</td>
                            <td className="py-4 px-6 border-b border-gray-200 dark:border-gray-600">
                                <a
                                    className="select-none no-underline rounded-md text-white bg-sky-600 hover:text-white hover:bg-sky-500 text-sm py-2 px-4"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={"mailto:careers@edplato.com?subject=" + encodeURIComponent("Application for " + item.title.en)}
                                >
                                    Apply
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    </main>

export default PageCareers