import React from 'react';
import validator from 'validator';
import { useNavigate } from "react-router-dom";
import EdInput from './../../components/edInput';
import EdButton from './../../components/edButton';
import EdHelmet from '../../components/edHelmet';


const PageSignIn = () => {
    const [errors, setErrors] = React.useState({});
    const [values, setValues] = React.useState({});
    const navigate = useNavigate();
    const _DEFAULT_EMAIL_AND_PASSWORD = "demo@edplato.com"

    const handleChange = (e) => {
        const { name, value, label } = e.target;
        if (!e || !e.target || values[name] === value) return;

        // Update current values
        values[name] = value;
        if (label) values[name + "Label"] = label;
        setValues({ ...values });

        // Update errors
        checkValidation();
    }

    const checkValidation = () => {
        let validationErrors = {};

        // isEmail
        if (!values.email)
            validationErrors.email = "L'e-mail est requis";
        else if (!validator.isEmail(values.email))
            validationErrors.email = "L'e-mail n'est pas valide";

        // isLength
        if (!values.password)
            validationErrors.password = "Le mot de passe est requis";
        else if (!validator.isLength(values.password, { min: 4 }))
            validationErrors.password = "Le mot de passe doit contenir au moins 4 caractères";

        // check validationErrors
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        }
        else {
            setErrors({});
            return true;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkValidation() === false) return;

        // Check if email and password are correct
        if (values.email !== _DEFAULT_EMAIL_AND_PASSWORD || values.password !== _DEFAULT_EMAIL_AND_PASSWORD) {
            setErrors({ submit: true });
        }
        else {
            setErrors({});
            window.localStorage.setItem("token", "demo");
            navigate("/dashboard")
        }
    }

    return <main className="grow py-12">
        <EdHelmet title="Sign In" description="Connectez-vous pour accéder à nos ressources éducatives et à nos services exclusifs. Disponible pour les enseignants et les étudiants." />
        <section className="max-w-sm lg:max-w-md px-3 mx-auto text-center">
            <h1 className="font-bold text-3xl my-3">Connectez-vous à votre compte</h1>
            <div>Connectez-vous pour accéder à nos ressources éducatives et à nos services exclusifs. Disponible pour les enseignants et les étudiants. Utilisation de <code>{_DEFAULT_EMAIL_AND_PASSWORD}</code> comme e-mail et mot de passe.</div>
            <form className="text-left mt-8" onSubmit={handleSubmit}>
                <EdInput
                    type="email"
                    name="email"
                    label="Adresse e-mail"
                    defaultValue={values.email}
                    autoComplete={true}
                    isRequired={true}
                    onChange={handleChange}
                    errors={errors}
                    lang="fr"
                />
                <EdInput
                    type="password"
                    name="password"
                    label="Mot de passe"
                    autoComplete={false}
                    isRequired={true}
                    onChange={handleChange}
                    errors={errors}
                    lang="fr"
                />

                <EdButton color="sky" className="w-full">Se connecter</EdButton>
                {(errors.submit === true) &&
                    <div className="text-red-500 text-xs mt-1">
                        <div>L'e-mail ou le mot de passe est incorrect.</div>
                        <div>Utilisation de {_DEFAULT_EMAIL_AND_PASSWORD} comme e-mail et mot de passe.</div>
                    </div>
                }
            </form>
        </section>
    </main>
}

export default PageSignIn