export const dataGeneratedTests = [
    {
        order: 1,
        difficulty: "Easy",
        question: "Which of the following is NOT one of the five forces in Porter's Five Forces framework?",
        answers: [
            {
                answer: "Bargaining power of suppliers",
                correct: false
            },
            {
                answer: "Threat of new entrants",
                correct: false
            },
            {
                answer: "Industry rivalry",
                correct: false
            },
            {
                answer: "Market segmentation",
                correct: true
            },
        ],
    },
    {
        order: 2,
        difficulty: "Easy",
        question: "Which of the following is NOT a component of SWOT analysis?",
        answers: [
            {
                answer: "Strengths",
                correct: false
            },
            {
                answer: "Weaknesses",
                correct: false
            },
            {
                answer: "Opportunities",
                correct: false
            },
            {
                answer: "Threats",
                correct: false
            },
            {
                answer: "Tactics",
                correct: true
            },
        ],
    },
    {
        order: 3,
        difficulty: "Medium",
        question: "Which of the following strategies involves targeting a narrow segment of the market with specialized products or services?",
        answers: [
            {
                answer: "Differentiation strategy",
                correct: false
            },
            {
                answer: "Cost leadership strategy",
                correct: false
            },
            {
                answer: "Focus strategy",
                correct: true
            },
            {
                answer: "Integration strategy",
                correct: false
            },
        ],
    },
    {
        order: 4,
        difficulty: "Medium",
        question: "Which of the following is a strategic management framework that focuses on aligning an organization's internal strengths and weaknesses with its external opportunities and threats?",
        answers: [
            {
                answer: "Balanced Scorecard",
                correct: false
            },
            {
                answer: "BCG Matrix",
                correct: false
            },
            {
                answer: "PESTEL analysis",
                correct: false
            },
            {
                answer: "SWOT analysis",
                correct: true
            },
        ],
    },
    {
        order: 5,
        difficulty: "Hard",
        question: "Which of the following terms refers to the process of transferring decision-making authority and responsibility to lower levels of an organization?",
        answers: [
            {
                answer: "Centralization",
                correct: false
            },
            {
                answer: "Decentralization",
                correct: true
            },
            {
                answer: "Outsourcing",
                correct: false
            },
            {
                answer: "Offshoring",
                correct: false
            },
        ],
    },
]