import React from 'react';
import BlockHero from './../../components/blockHero';
//import lottieKidsAroundMonitor from "./../../assets/lottie-kids-around-monitor.json";
//import lottieLaptopAndBooks from "./../../assets/lottie-laptop-and-books.json";
import lottieThreeStudents from "./../../assets/lottie-three-students.json";
import lottieFlyingSudent from "./../../assets/lottie-flying-male-student.json";
import lottieFlyingProf from "./../../assets/lottie-flying-female-prof.json";
import BlockFeatured from './../../components/blockFeatured';
import BlockFAQ from './../../components/blockFAQ';
import EdHelmet from '../../components/edHelmet';
import BlockCustomers from './../../components/blockCustomers';


const PageHome = () =>
    <React.Fragment>
        <EdHelmet />

        <BlockHero
            animationData={lottieThreeStudents}
            title="EdPLATO - Plateforme alimentée par l'IA qui transforme l'apprentissage"
            description="EdPLATO est une plateforme éducative avancée qui propose des cours générés par l'IA et supervisés par des professeurs, des études de cas, des mini-quiz, des évaluations basées sur l'IA et d'autres outils d'évaluation."
            ctaLabel="Inscrivez-vous maintenant"
            ctaLink="/fr/register"
        />

        <BlockCustomers lang="fr" />

        <BlockFeatured
            animationData={lottieFlyingProf}
            animationPosition="left"
            title="Tests adaptatifs IA"
            description="Libérez la puissance de l'IA pour transformer les évaluations : découvrez des tests personnalisés, dynamiques et adaptatifs pour améliorer les résultats d'apprentissage."
            items={[
                {
                    icon: "fas fa-globe",
                    title: "Ajustement dynamique de la difficulté",
                    description: "Ajustez la complexité en fonction du niveau de compétence de chacun."
                },
                {
                    icon: "fas fa-chart-line",
                    title: "Suivi de la progression en temps réel",
                    description: "Surveillez et analysez en continu les performances des étudiants en temps réel."
                },
                {
                    icon: "fas fa-chart-bar",
                    title: "Rapports détaillés sur les performances",
                    description: "Obtenez des informations complètes avec des graphiques et des rapports visuels pour améliorer les résultats d'apprentissage."
                }
            ]}
        />


        <BlockFeatured
            animationData={lottieFlyingSudent}
            animationPosition="right"
            title="Apprentissage personnalisé avec l'IA"
            description="Responsabiliser les utilisateurs grâce à l'IA : création de parcours d'apprentissage uniques et personnalisés pour la réussite et la croissance individuelles."
            items={[
                {
                    icon: "fas fa-rocket",
                    title: "Parcours d'apprentissage personnalisés",
                    description: "Des parcours uniques adaptés aux besoins individuels."
                },
                {
                    icon: "fas fa-book",
                    title: "Recommandations de contenu intelligentes",
                    description: "Des documents pertinents suggérés par l'IA."
                },
                {
                    icon: "fas fa-lightbulb",
                    title: "Système de feedback personnalisé",
                    description: "Des suggestions individualisées pour améliorer la compréhension."
                }
            ]}
        />


        <BlockFAQ
            title="Foire aux questions fréquentes"
            description="Révolutionnez l'éducation avec des évaluations personnalisées générées par l'IA conçues pour simplifier l'enseignement et maximiser l'apprentissage."
            items={[
                {
                    question: "Qu'est-ce qu'EdPLATO ?",
                    answer: "EdPLATO est un site web d'apprentissage personnalisé et de tests adaptatifs conçu pour offrir des expériences éducatives sur mesure et des évaluations pour améliorer les résultats d'apprentissage.",
                },
                {
                    question: "Qui est à l'origine de la création d'EdPLATO ?",
                    answer: "EdPLATO est soutenu par une équipe de professionnels internationaux ayant plus de 20 ans d'expérience dans l'enseignement et l'apprentissage de niveau supérieur, complétée par une équipe technique compétente ayant une vaste expérience en technologie, LLM et ML. Leur expertise combinée guide le développement de solutions innovantes en technologie éducative d'EdPLATO, visant à combler l'écart entre les méthodes d'enseignement traditionnelles et les technologies émergentes.",
                },
                {
                    question: "Comment EdPLATO personnalise-t-il l'apprentissage ?",
                    answer: "EdPLATO utilise des algorithmes avancés et la technologie de l'IA pour adapter les matériaux d'apprentissage et les évaluations en fonction des besoins individuels des étudiants, de leurs préférences et de leur niveau de compétence.",
                },
                {
                    question: "Quels sujets ou thèmes couvre EdPLATO ?",
                    answer: "La plateforme est conçue pour répondre à différents niveaux éducatifs et programmes. Actuellement, EdPLATO couvre des sujets liés aux sciences sociales et à l'administration des affaires, avec des projets d'élargir son offre pour inclure les mathématiques, les langues, les arts, les études sociales et bien plus encore.",
                },
                {
                    question: "Comment fonctionne le test adaptatif sur EdPLATO ?",
                    answer: "Le test adaptatif sur EdPLATO ajuste dynamiquement le niveau de difficulté des questions en fonction des performances et du niveau de compétence de chacun. Cela garantit à chaque étudiant de recevoir des questions correspondant à leur niveau de compétence actuel, offrant une expérience d'apprentissage optimisée.",
                },
                {
                    question: "Est-ce que EdPLATO peut suivre ma progression ?",
                    answer: "Oui, EdPLATO propose un suivi en temps réel de la progression. Il surveille et analyse vos performances lorsque vous interagissez avec la plateforme, fournissant des informations sur vos points forts, les domaines à améliorer et votre progression globale.",
                },
                {
                    question: "Les matériaux d'apprentissage sur EdPLATO sont-ils créés par de vrais éducateurs ?",
                    answer: "Oui ! Les matériaux d'apprentissage d'EdPLATO sont développés par une équipe d'éducateurs qualifiés qui collaborent avec la technologie de l'IA pour créer un contenu attrayant et efficace.",
                },
                {
                    question: "Est-ce que EdPLATO convient à tous les groupes d'âge ?",
                    answer: "Bien qu'EdPLATO se concentre actuellement sur les apprenants de niveau universitaire, nous avons pour vision d'élargir notre champ de services pour inclure les jeunes apprenants à l'avenir. Notre objectif est de fournir des ressources éducatives et des tests adaptatifs pour les apprenants de tous les groupes d'âge, des niveaux d'école élémentaire, du secondaire et de l'université.",
                },
                {
                    question: "Comment puis-je commencer avec EdPLATO ?",
                    answer: "Pour commencer avec EdPLATO, il vous suffit de visiter notre site web et de créer un compte. À partir de là, vous pouvez explorer les cours disponibles et les évaluations adaptatives, personnaliser votre expérience d'apprentissage et suivre votre progression.",
                },
                {
                    question: "Y a-t-il des frais associés à l'utilisation d'EdPLATO ?",
                    answer: "EdPLATO propose des options d'abonnement gratuites et premium. Alors que certaines fonctionnalités et contenus sont disponibles gratuitement, certaines fonctionnalités avancées et ressources supplémentaires peuvent nécessiter des frais d'abonnement.",
                },
            ]}
        />

    </React.Fragment>
export default PageHome;
