import React from 'react';
import EdInput from './../../components/edInput';
import EdButton from './../../components/edButton';
import validator from 'validator';
import Lottie from "lottie-react";
import animationData from "./../../assets/lottie-checked.json";
import EdHelmet from '../../components/edHelmet';

const PageRegister = () => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [values, setValues] = React.useState({});

    const handleChange = (e) => {
        const { name, value, label } = e.target;
        if (!e || !e.target || values[name] === value) return;

        // Update current values
        values[name] = value;
        if (label) values[name + "Label"] = label;
        setValues({ ...values });

        // Update errors
        checkValidation();
    }

    const checkValidation = () => {
        let validationErrors = {};

        // isEmail
        if (!values.email)
            validationErrors.email = "L'e-mail est requis";
        else if (!validator.isEmail(values.email))
            validationErrors.email = "L'e-mail n'est pas valide";

        // isLength
        if (!values.password)
            validationErrors.password = "Le mot de passe est requis";
        else if (!validator.isLength(values.password, { min: 4 }))
            validationErrors.password = "Le mot de passe doit contenir au moins 4 caractères";

        // equals
        if (!values.password && !values.confirmPassword) {
            validationErrors.password = "Le mot de passe est requis";
            validationErrors.confirmPassword = "La confirmation du mot de passe est requise";
        }
        else if (!validator.equals(values.password, values.confirmPassword)) {
            validationErrors.password = "Le mot de passe et la confirmation du mot de passe doivent être identiques";
            validationErrors.confirmPassword = "Le mot de passe et la confirmation du mot de passe doivent être identiques";
        }

        // isAlpha
        if (!values.firstName)
            validationErrors.firstName = "Le prénom est requis";
        else if (!validator.isAlpha(values.firstName))
            validationErrors.firstName = "Le prénom doit être alphabétique";

        if (!values.lastName)
            validationErrors.lastName = "Le nom de famille est requis";
        else if (!validator.isAlpha(values.lastName))
            validationErrors.lastName = "Le nom de famille doit être alphabétique";

        // Vérifier si le pays est sélectionné
        if (!values.country || values.country === "") {
            validationErrors.country = "Le pays est requis";
        }

        // Vérifier si l'université est sélectionnée
        if (!values.university || values.university === "") {
            validationErrors.university = "L'université est requise";
        }

        // Vérifier si le rôle est sélectionné
        if (!values.role || values.role === "") {
            validationErrors.role = "Le rôle est requis";
        }

        // Vérifier les champs liés à l'étudiant
        if (values.role === "student") {
            if (values.enrollmentStatus === undefined || values.enrollmentStatus === "") {
                validationErrors.enrollmentStatus = "Le statut d'inscription est requis";
            }
            if (values.expectedGraduationYear === undefined || values.expectedGraduationYear === "") {
                validationErrors.expectedGraduationYear = "L'année prévue d'obtention du diplôme est requise";
            }
            if (values.degreeProgram === undefined || values.degreeProgram === "") {
                validationErrors.degreeProgram = "Le programme d'études est requis";
            }
        }

        // Vérifier les champs liés à l'enseignant
        if (values.role === "educator") {
            if (values.academicPosition === undefined || values.academicPosition === "") {
                validationErrors.academicPosition = "Le poste académique est requis";
            }
            if (values.academicDepartment === undefined || values.academicDepartment === "") {
                validationErrors.academicDepartment = "Le département académique est requis";
            }
        }

        // Vérifier les erreurs de validation
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        }
        else {
            setErrors({});
            return true;
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkValidation() === false) return;

        // Save to database
        console.log(values);

        // Submit form
        window.scrollTo(0, 0)
        setIsSubmitted(true);
    }

    return <main className="grow py-12">
        <EdHelmet title="Inscrivez votre compte" description="Inscrivez-vous pour accéder aux supports pédagogiques, bénéficier de remises exclusives et participer à des webinaires. Disponible pour les enseignants et les étudiants." />
        {isSubmitted === false &&
            <section className="max-w-sm md:max-w-lg px-3 mx-auto text-center">
                <h1 className="font-bold text-3xl my-3">Inscrivez votre compte</h1>
                <div>Inscrivez-vous pour accéder aux supports pédagogiques, bénéficier de remises exclusives et participer à des webinaires. Disponible pour les enseignants et les étudiants.</div>
                <form className="text-left mt-8 space-y-6" onSubmit={handleSubmit}>
                    <EdInput
                        type="text"
                        name="firstName"
                        label="Prénom"
                        defaultValue={values.firstName}
                        autoComplete="firstName"
                        onChange={handleChange}
                        isRequired={true}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="text"
                        name="lastName"
                        label="Nom de famille"
                        defaultValue={values.lastName}
                        autoComplete={true}
                        onChange={handleChange}
                        isRequired={true}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="email"
                        name="email"
                        label="Adresse e-mail"
                        defaultValue={values.email}
                        autoComplete={true}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="password"
                        name="password"
                        label="Mot de passe"
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="password"
                        name="confirmPassword"
                        label="Confirmer le mot de passe"
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="edUniversity"
                        name="university"
                        label="Saisir le nom de l'université"
                        defaultValue={values.university}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="edCountry"
                        name="country"
                        label="Sélectionner le pays"
                        defaultValue={values.country}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />
                    <EdInput
                        type="edAcademicRole"
                        name="role"
                        label="Sélectionner le rôle"
                        defaultValue={values.role}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="fr"
                    />

                    {values.role === "student" &&
                        <EdInput
                            type="edEnrollmentStatus"
                            name="enrollmentStatus"
                            label="Sélectionner le statut d'inscription"
                            defaultValue={values.enrollmentStatus}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="fr"
                        />
                    }

                    {values.role === "student" &&
                        <EdInput
                            type="edExpectedGraduationYear"
                            name="expectedGraduationYear"
                            label="Sélectionner l'année prévue d'obtention du diplôme"
                            defaultValue={values.expectedGraduationYear}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="fr"
                        />
                    }

                    {values.role === "student" &&
                        <EdInput
                            type="edAcademicDegree"
                            name="degreeProgram"
                            label="Sélectionner le programme d'études"
                            defaultValue={values.degreeProgram}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="fr"
                        />
                    }

                    {values.role === "educator" &&
                        <EdInput
                            type="edAcademicPosition"
                            name="academicPosition"
                            label="Sélectionner le poste académique"
                            defaultValue={values.academicPosition}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="fr"
                        />
                    }

                    {values.role === "educator" &&
                        <EdInput
                            type="edAcademicDepartment"
                            name="academicDepartment"
                            label="Sélectionner le département académique"
                            defaultValue={values.academicDepartment}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="fr"
                        />
                    }

                    {
                        (values.role === "student" || values.role === "educator")
                            ? <EdButton color="sky" className="w-full mt-3">S'inscrire</EdButton>
                            : <EdButton color="white" className="w-full mt-3" disabled={true} readOnly={true}>S'inscrire</EdButton>
                    }
                </form>
            </section>
        }
        {isSubmitted === true &&
            <section className="max-w-sm md:max-w-lg px-3 mx-auto text-center -mt-10 mb-10">
                <Lottie animationData={animationData} loop={false} autoplay={true} />
                <h1 className="font-bold text-3xl -mt-10">Merci !</h1>
                <div>
                    Votre soumission a été reçue. Veuillez vérifier votre ✉️ (e-mail)
                    pour confirmer votre compte. Si vous ne recevez pas d'e-mail
                    dans les 24 heures, veuillez nous contacter à l'adresse <a
                        href="mailto:support@edplato.com?subject=Urgent%3A%20Account%20Confirmation%20Email%20Not%20Received&body=Hi%20Support%2C%0D%0A%0D%0AI%20didn't%20receive%20my%20account%20confirmation%20email.%20Please%20help.%0D%0A%0D%0AThanks%2C%0D%0A"
                        className="text-sky-700"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support [at] edplato.com
                    </a>
                </div>
            </section>
        }

    </main>
}

export default PageRegister