
const BlockWizard = ({ dataset, selectedIndex }) =>
    <ol className="flex items-center w-full text-gray-500 dark:text-gray-400 p-0 m-0 list-none">
        {dataset.map((item, index) =>
            <li className={`
                flex 
                items-center 
                ${index !== dataset.length - 1
                    ? `
                        md:after:content-[''] 
                        md:w-full 
                        after:w-full
                        after:h-1 
                        after:m-4
                        after:border-b 
                        after:border-1 
                        after:hidden 
                        md:after:inline-block 
                        after:border-gray-200 
                        dark:after:border-gray-700 
                    `
                    : `
                        md:shrink-0
                    `
                }
                ${index === selectedIndex
                    ? " text-sky-600 dark:text-sky-500 after:border-sky-600 dark:after:border-sky-500"
                    : " text-gray-500 dark:text-gray-400"
                }
            `} key={index}>
                <span className={`
                        flex items-center justify-center 
                        w-8 h-8 
                        rounded-full 
                        shrink-0 
                        mr-2

                        after:content-['/'] 
                        md:after:hidden 
                        after:mx-2 
                        after:text-gray-200 
                        ${index === 0 ? "after:hidden" : ""}

                        md:border border-gray-500 dark:border-gray-400 
                        ${index === selectedIndex ? "bg-sky-600 dark:bg-sky-500 text-gray-200 dark:text-gray-800 border-sky-600 dark:border-sky-500 font-semibold" : ""}
                    `}>
                    <span className="hidden md:inline-block">{index + 1}</span>
                </span>
                <span className="flex flex-col items-start justify-center md:whitespace-nowrap">
                    <div className={`${index === selectedIndex ? "font-semibold" : "font-medium"} leading-tight`}>{item.title}</div>
                    <div className="text-xs">{item.description}</div>
                </span>
            </li>
        )}
    </ol>

export default BlockWizard