import React from 'react';
import EdHelmet from './../../components/edHelmet';

const PagePricing = () =>
    <main>
        <EdHelmet title="Pricing" description="Our goal is to provide our users with a seamless and valuable experience. As we are currently refining our services to best suit your needs, we are glad to offer a Free Trial of our platform." />
        <section>
            <h1>Pricing</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Our goal is to provide our users with a seamless and valuable experience. As we are currently refining our services to best suit your needs, we are glad to offer a
                <a href="/register" target="_blank" rel="noopener noreferrer"> Free Trial </a>
                of our platform.
            </p>
            <hr />
        </section>
    </main>

export default PagePricing;