const EdTooltipInfo = ({ children }) =>
    <sup className="group relative cursor-pointer">
        <i className="fa-solid fa-circle-info text-gray-500 dark:text-gray-400 hover:text-sky-700 dark:hover:text-sky-600 cursor-pointer"></i>
        <span className="
                pointer-events-none absolute 
                -top-7 left-0 
                w-max rounded px-2 py-1 text-sm font-medium 
                bg-sky-900 text-gray-50 
                opacity-0 shadow transition-opacity group-hover:opacity-100
            ">
            {children}
        </span>
    </sup>

export default EdTooltipInfo