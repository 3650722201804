export const dataOurTeam = [
    {
        type: "team",
        name: "Yasaman Gorji",
        title: { en: "CEO & Founder", fr: "PDG & Fondateur" },
        description: {
            en: "My journey in education spans over 20 years, during which I have been both a student and a teacher. These experiences have taken me across three continents - North America, Europe, and further East. Through my diverse encounters with education, I have gained a profound understanding of the challenges faced by educators and the obstacles encountered by learners. I have also witnessed the transformative power of educational technology and its ever-evolving nature. I am fully committed to developing cutting-edge ed-tech solutions that bridge the gap between traditional teaching methods and emerging technologies.",
            fr: "Mon parcours dans l’éducation s’étend sur plus de 20 ans, au cours desquels j’ai été à la fois étudiant et enseignant. Ces expériences m’ont emmené sur trois continents - l’Amérique du Nord, l’Europe et encore plus à l’Est. À travers mes rencontres diverses avec l’éducation, j’ai acquis une profonde compréhension des défis auxquels les éducateurs font face et des obstacles rencontrés par les apprenants. J’ai également été témoin du pouvoir transformateur de la technologie éducative et de sa nature en constante évolution. Je suis pleinement engagé à développer des solutions éducatives de pointe qui comblent le fossé entre les méthodes d’enseignement traditionnelles et les technologies émergentes.",
        },
        social: [
            { type: "linkedin", url: "https://www.linkedin.com/in/yasaman-gorji/" },
            { type: "square-google-plus", url: "https://scholar.google.ca/citations?user=QnA4FUIAAAAJ" },
        ],
    },
    {
        type: "team",
        name: "Micha Cien",
        title: { en: "CRM Expert", fr: "Expert CRM" },
        description: {
            en: "With fluency in English, French, Spanish, and Polish, Micha brings a wealth of language expertise to our team. Her background includes extensive experience working with renowned Canadian companies like Air Canada, Felix and Paul Studios, a Montreal-based company specializing in virtual reality, augmented reality, where she honed her skills in customer service. Micha's unique blend of language proficiency, customer service experience, and entrepreneurial mindset make her an invaluable asset to our team at EdPLATO.",
            fr: "Avec une maîtrise de l'anglais, du français, de l'espagnol et du polonais, Micha apporte une richesse d'expertise linguistique à notre équipe. Son parcours comprend une vaste expérience de travail avec des entreprises canadiennes renommées comme Air Canada, Felix and Paul Studios, une entreprise montréalaise spécialisée dans la réalité virtuelle, la réalité augmentée, où elle a perfectionné ses compétences en service à la clientèle. Le mélange unique de maîtrise des langues, d'expérience du service à la clientèle et d'état d'esprit entrepreneurial de Micha en fait un atout inestimable pour notre équipe chez EdPLATO.",
        },
        social: [
            {
                type: "linkedin", url: "https://www.linkedin.com/in/micha-cien/"
            },
        ],
    },
    {
        type: "advisor",
        name: "Yasin Salimibeni",
        title: { en: "AI Advisor", fr: "Conseiller en IA" },
        description: {
            en: "Expert Data Scientist with deep tech experience. Specializes in NLP, demand analysis, and actionable data-driven strategies.",
            fr: "Expert en science des données avec une solide expérience en finance/tech. Spécialisé en TAL (Traitement Automatique des Langues), analyse de la demande et stratégies basées sur les données.",
        },
        social: [
            { type: "linkedin", url: "https://www.linkedin.com/in/yasin-salimibeni" },
            { type: "square-google-plus", url: "https://scholar.google.ca/citations?user=NXKF5hEAAAAJ" },
        ],
    },
    {
        type: "advisor",
        name: "Sarah Awad",
        title: { en: "Marketing Advisor", fr: "Conseillère en Marketing" },
        description: {
            en: "As a seasoned Marketing Advisor with over 10 + years of experience, Sarah brings a wealth of expertise in crafting strategic marketing plans to drive business growth. She is also proficient in market analysis, brand positioning, and targeted audience segmentation. Her advice and role is to provide actionable insights and recommendations based on market trends and consumer behavior.",
            fr: "Forte d'une expérience de plus de 10 ans en tant que conseillère en marketing, Sarah possède une riche expertise dans l'élaboration de plans marketing stratégiques pour stimuler la croissance des entreprises. Elle est également compétente en analyse de marché, positionnement de marque et segmentation de l'audience cible. Son rôle est de fournir des informations et recommandations exploitables basées sur les tendances du marché et le comportement des consommateurs.",
        },
        social: [
            { type: "linkedin", url: "https://www.linkedin.com/in/sarah-awad-b2649223/" },
        ],
    },
    {
        type: "advisor",
        name: "Amir Astaneh",
        title: { en: "Tech Advisor", fr: "Conseiller en Tech" },
        description: {
            en: "20-year expertise in computer programming and web design.",
            fr: "Expertise de 20 ans en programmation informatique et conception web.",
        },
        social: [
            { type: "linkedin", url: "https://www.linkedin.com/in/amastaneh/" },
            { type: "github", url: "https://github.com/amastaneh" },
            { type: "instagram", url: "https://www.instagram.com/amastaneh/" },
            { type: "square-google-plus", url: "https://scholar.google.ca/citations?user=lqZYW6sAAAAJ" },
        ],
    },
    {
        type: "advisor",
        name: "Parham Darbandi",
        title: { en: "Data Advisor", fr: "Conseiller en Données" },
        description: {
            en: "Cloud Engineer & Big Data Lead; expert in query optimization; manages onshore/offshore teams; critical system overseer; POC & ETL specialist.",
            fr: "Ingénieur Cloud & Responsable Big Data; expert en optimisation de requêtes; gère les équipes locales et délocalisées; superviseur de systèmes critiques; spécialiste POC & ETL.",
        },
        social: [
            { type: "linkedin", url: "https://www.linkedin.com/in/pdarbandi" },
        ],
    },
]
