import React from "react"
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { dataCountries } from './../data/dataCountries';
import { dataAcademicRole } from './../data/dataAcademicRole';
import { dataUniversities } from './../data/dataUniversities';
import { dataEnrollmentStatus } from './../data/dataEnrollmentStatus';
import { dataAcademicDegree } from './../data/dataAcademicDegree';
import { dataAcademicPosition } from './../data/dataAcademicPosition';
import { dataAcademicDepartments } from './../data/dataAcademicDepartment';

const EdInput = ({
    name,
    label,
    type,
    value,
    isRequired = false,
    autoComplete = undefined,
    className,
    errors,
    onChange,
    defaultValue,
    lang = "en",
}) => {

    const showSelect = (dataset, lang) =>
        <Select
            cacheOptions
            defaultOptions
            placeholder={label}
            defaultValue={defaultValue && dataset
                ?.filter((c) => c.id === defaultValue)
                ?.map((c) => ({ value: c.id, label: (c[lang]?.name || c.name) }))[0]}
            options={dataset
                .map((c) => ({ value: c.id, label: c[lang]?.name || c.name }))
                .sort((a, b) => a.label.localeCompare(b.label))
            }
            onChange={(selected) => {
                onChange({
                    target: {
                        name: name,
                        value: selected?.value,
                        label: selected?.label
                    }
                })
            }}
            className={className}
        />

    const showAsyncSelect = (dataset) =>
        <AsyncSelect
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage: () => null,
            }}
            cacheOptions
            defaultOptions
            placeholder={label}
            defaultValue={defaultValue && dataset
                ?.filter((c) => c.id === defaultValue)
                ?.map((c) => ({ value: c.id, label: c.name }))[0]}
            loadOptions={(phrase, callback) =>
                (phrase && phrase.length > 1)
                    ? callback(dataset
                        ?.filter((c) => c.name.toLowerCase().includes(phrase.toLowerCase()))
                        ?.slice(0, 10)
                        ?.map((c) => ({ value: c.id, label: c.name })))
                        ?.sort((a, b) => a.label.localeCompare(b.label))
                    : callback([])
            }
            onChange={(selected) => {
                onChange({ target: { name: name, value: selected?.value, label: selected?.label } })
            }}
            className={className}
        />

    const showInput = () => {
        switch (type) {
            case "edCountry":
                return showSelect(dataCountries, lang)

            case "edUniversity":
                return showAsyncSelect(dataUniversities)

            case "edAcademicRole":
                return showSelect(dataAcademicRole, lang)

            case "edEnrollmentStatus":
                return showSelect(dataEnrollmentStatus, lang)

            case "edExpectedGraduationYear":
                return showSelect([
                    { "id": "2023", "name": "2023" },
                    { "id": "2024", "name": "2024" },
                    { "id": "2025", "name": "2025" },
                    { "id": "2026", "name": "2026" },
                    { "id": "2027", "name": "2027" },
                    { "id": "2028", "name": "2028" },
                    { "id": "2029", "name": "2029" },
                    { "id": "2030", "name": "2030" },
                    { "id": "2031", "name": "2031" },
                    { "id": "2032", "name": "2032" },
                    { "id": "2033", "name": "2033" },
                ])

            case "edAcademicDegree":
                return showSelect(dataAcademicDegree, lang)

            case "edAcademicPosition":
                return showSelect(dataAcademicPosition, lang)

            case "edAcademicDepartment":
                return showSelect(dataAcademicDepartments, lang)

            case "edSampleTestSubject":
                return showSelect([
                    { "id": "strategy", "en": { "name": "Strategy" }, "fr": { "name": "Stratégie" } },
                    { "id": "marketing", "en": { "name": "Marketing" }, "fr": { "name": "Marketing" } },
                    { "id": "finance", "en": { "name": "Financial Management" }, "fr": { "name": "Gestion Financière" } },
                    { "id": "operations", "en": { "name": "Operations Management" }, "fr": { "name": "Gestion des Opérations" } }
                ], lang)

            case "edSampleTestNumber":
                return showSelect([
                    { "id": 1, "name": "1" },
                    { "id": 2, "name": "2" },
                    { "id": 3, "name": "3" },
                    { "id": 4, "name": "4" },
                    { "id": 5, "name": "5" },
                ])

            default:
                return <input
                    type={type}
                    id={name}
                    name={name}
                    placeholder={label}
                    value={value}
                    className={`
                px-3 py-2 
                rounded-md appearance-none relative block w-full sm:text-sm 
                border border-gray-300 
                placeholder-gray-500 text-gray-900 
                focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 
                ${className ?? " "}`}
                    required={isRequired}
                    autoComplete={(autoComplete === undefined || autoComplete === false) ? "do-not-autofill" : label}
                    onChange={onChange}
                />
        }
    }

    return <div className="my-5">
        <label htmlFor={name} className="sr-only">{label}</label>
        {showInput()}
        {(errors && errors[name]) && <div className="text-red-500 text-xs mt-1">{errors[name]}</div>}
    </div>
}

export default EdInput