import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageDocumentation = () =>
    <main>
        <EdHelmet title="Documentation" description="Trouvez des guides complets, des tutoriels et des ressources pour maximiser votre compréhension et l'utilisation des fonctionnalités de la plateforme EdPLATO." />
        <section>
            <h1>Documentation</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Trouvez des guides complets, des tutoriels et des ressources pour maximiser votre compréhension et l'utilisation
                des fonctionnalités de la <a className="mr-1" href="/">plateforme EdPLATO</a>.
            </p>
            <hr />
        </section>
    </main>

export default PageDocumentation