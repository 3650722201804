import React from 'react';
import EdHelmet from './../../components/edHelmet';

const PagePolicy = () =>
    <main>
        <EdHelmet title="Policies" description="Transparency and guidelines that govern your experience with EdPLATO, ensuring a secure and fair learning environment." />
        <section>
            <h1>Policies</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Transparency and guidelines that govern your experience with <a className="" href="/">EdPLATO</a>, ensuring a secure and fair learning environment.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PagePolicy;