import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PagePrivacy = () =>
    <main>
        <EdHelmet title="Privacy Policy" description="Your data protection matters. Learn how EdPLATO safeguards your information and respects your privacy throughout your educational journey." />
        <section>
            <h1>Privacy Policy</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Your data protection matters. Learn how <a className="mx-1" href="/">EdPLATO</a> safeguards your information and respects your privacy throughout your educational journey.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PagePrivacy