import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import { Helper } from "./helper";


const handleException = (ex) => {
    if (Helper.isPROD()) Sentry.captureException(ex);
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Unexpected error occured.",
        footer: 'Please contact with EdPLATO support.',
        confirmButtonText: 'OK',
        customClass: {
            confirmButton: 'btn btn-danger',
        },
    })
}


export const exceptionHelper = {
    handleException,
}