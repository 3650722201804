import React from "react"
import { Outlet, useParams } from "react-router-dom";
import BlockHeader from './../../components/blockHeader';
import BlockFooter from './../../components/blockFooter';
import { saveLang } from "../../helper/languageHelper";

const LayoutMain = () => {
    // This is to force the page to reload when the language changes
    const params = useParams();
    React.useEffect(() => { }, [params])

    saveLang("en")
    return <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900">
        <BlockHeader
            menuList={[
                ["Home", "/"],
                ["Solutions", "/solutions"],
                ["Pricing", "/pricing"],
                ["Blog", "/blog"],
                ["About", "/about"],
                ["Contact", "/contact"],
                ["FR", window.location.href.replace(window.location.origin, window.location.origin + "/fr")],
            ]}
            buttonList={[
                ["Sign In", "/signin", "white"],
                ["Register", "/register", "blue"],
            ]}
        />
        <Outlet />
        <BlockFooter
            menuList={[
                {
                    title: "Product",
                    items: [
                        { title: "Solutions", url: "/solutions" },
                        { title: "Pricing", url: "/pricing" },
                        { title: "Features", url: "/solutions" },
                        { title: "Integrations", url: "/solutions" },
                    ]
                },
                {
                    title: "Company",
                    items: [
                        { title: "About", url: "/about" },
                        { title: "Blog", url: "/blog" },
                        { title: "Careers", url: "/career" },
                        { title: "Contact", url: "/contact" },
                    ]
                },
                {
                    title: "Connect",
                    items: [
                        { title: "LinkedIn", icon: "fab fa-linkedin-in", url: "https://linkedin.com/company/edplato-com" },
                        { title: "Twitter", icon: "fab fa-twitter", url: "https://twitter.com/edplato_com" },
                        { title: "Instagram", icon: "fab fa-instagram", url: "https://www.instagram.com/edplato_com/" },
                        { title: "Youtube", icon: "fab fa-youtube", url: "https://www.youtube.com/@EdPLATO" },
                    ]
                }
            ]}
            ctaTitle="EdPLATO is an advanced educational platform that offers AI-generated professor-supervised courses, cases, pop quizzes, AI-grading and other evaluation tools."
            ctaLabel="Join now!"
            ctaLink="/register"
        />
    </div>
}

export default LayoutMain