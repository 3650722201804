import React from "react";
import { Disclosure } from "@headlessui/react";
import EdButton from './edButton';
import { NavLink, Link } from "react-router-dom";
import BlockDarkSwitch from './blockDarkSwitch';

const showMenu = ({ menuList, className }) =>
    menuList.map((menuItem, menuIndex) =>
        <NavLink key={menuIndex} className={"menu-item " + className ?? " "} to={menuItem[1].toLowerCase()}>{menuItem[0]}</NavLink>)

const showSideButtons = ({ buttonList, className }) =>
    (buttonList).map((menuItem, menuIndex) =>
        <EdButton to={menuItem[1].toLowerCase()} key={menuIndex} color={menuItem[2]} className={className ?? " "} >
            {menuItem[0]}
        </EdButton>)

const BlockHeader = ({ menuList, buttonList }) =>
    <header className="sticky top-0 z-40 flex-none mx-auto w-full bg-white dark:bg-gray-900 left-0 border-b border-gray-200 dark:border-gray-700">
        <nav className="w-full px-3 py-3 mx-auto lg:flex lg:justify-between lg:px-3">
            {/* Hamburguer Menu */}
            <Disclosure>
                {({ open }) =>
                    <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                        {/* Main Logo */}
                        <Link href="/" className="no-underline hover:no-underline" style={{ fontFamily: "Cambria" }}>
                            <span className="flex items-center space-x-2 text-2xl font-medium text-sky-600 dark:text-gray-100">
                                <img src="/logo.png" alt="EdPLATO" width="32" height="32" className="w-8" />
                                <span className="text-2xl lg:text-3xl font-medium">EdPLATO</span>
                            </span>
                        </Link>

                        {/* Mobile Hamburguer Menu */}
                        <Disclosure.Button
                            aria-label="Toggle Menu"
                            className="px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-sky-600 focus:text-sky-600 focus:bg-sky-200 focus:outline-none dark:text-gray-300 dark:focus:bg-trueGray-700 dark:hover:text-gray-100 dark:focus:text-gray-100 dark:hover:bg-gray-700">
                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                {open && <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />}
                                {!open && <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />}
                            </svg>
                        </Disclosure.Button>

                        {/* Mobile Menu */}
                        <Disclosure.Panel className="flex flex-wrap w-full my-5 lg:hidden">
                            {showMenu({ menuList, className: "w-full" })}
                            {showSideButtons({ buttonList, className: "w-full mt-3" })}
                            <BlockDarkSwitch className="mt-6" />
                        </Disclosure.Panel>
                    </div>
                }
            </Disclosure>

            {/* Main Menu */}
            <div className="hidden text-center lg:flex lg:items-center">
                <ul className="items-center justify-end flex-1 m-0 p-0 pt-6 list-none lg:pt-0 lg:flex">
                    {showMenu({ menuList, className: "mr-3 inline-block" })}
                </ul>
            </div>
            <div className="hidden mr-3 space-x-4 lg:flex nav__item">
                {showSideButtons({ buttonList, className: "ml-5" })}
                <BlockDarkSwitch />
            </div>
        </nav>
    </header>

export default BlockHeader