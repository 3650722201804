import React from 'react';

export const ContextTheme = React.createContext({})

export const ContextThemeProvider = ({ children }) => {
    const [theme, setTheme] = React.useState(null);

    return (
        <ContextTheme.Provider value={{ theme, setTheme, }}>
            {children}
        </ContextTheme.Provider>
    )
}
