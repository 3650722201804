import React from 'react';
import Lottie from "lottie-react";

const BlockFeatured = ({ animationData, animationPosition, title, description, items }) =>
    <section className="container p-8 mx-auto flex flex-wrap mb-20 lg:gap-10 lg:flex-nowrap">
        <div className={`flex items-center justify-center w-full lg:w-1/2 ${animationPosition === "right" ? "lg:order-1" : ""}`}>
            <Lottie
                animationData={animationData}
                loop={true}
                height={600}
                width={600}
            />
        </div>

        <div className={`flex flex-wrap items-center w-full lg:w-1/2 ${animationPosition === "right" ? "lg:justify-end" : ""}`}>
            <div>
                <div className="flex flex-col w-full mt-4">
                    <h3 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl dark:text-white">
                        {title}
                    </h3>

                    <p className="max-w-2xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300">
                        {description}
                    </p>
                </div>

                <div className="w-full mt-5">
                    {items?.map((item, index) =>
                        <div className="flex items-start mt-6 space-x-3" key={index}>
                            <div className="flex items-center justify-center flex-shrink-0 mt-1 bg-sky-600 rounded-md w-12 h-12 text-sky-50">
                                <i className={`${item.icon} fa-2x`}></i>
                            </div>
                            <div>
                                <h4 className="text-xl font-medium text-gray-800 dark:text-gray-200">
                                    {item.title}
                                </h4>
                                <p className="mt-1 text-gray-500 dark:text-gray-400">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </section>

export default BlockFeatured;
