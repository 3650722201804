import React from 'react';
import { Link } from "react-router-dom";

const EdButton = ({ children, ...props }) => {
    let className2 = "select-none no-underline px-6 py-2 rounded-md text-center "
    if (props?.disabled === true || props?.disabled === "disabled" || props?.readOnly === true || props?.readOnly === "readOnly") {
        className2 += "text-gray-400 bg-gray-200 border-2 border-solid border-gray-200"
        props.to = undefined
    }
    else if (props?.color === "white")
        className2 += `
            border-2 border-solid 
            text-gray-500 border-gray-200 
            hover:text-gray-700 hover:bg-gray-100 hover:border-gray-200
            focus:text-gray-700 focus:bg-gray-100 focus:border-gray-300
            dark:text-gray-400 dark:border-gray-700
            dark:hover:text-gray-100 dark:hover:bg-gray-700 dark:hover:border-gray-600
            dark:focus:text-gray-100 dark:focus:bg-gray-700 dark:focus:border-gray-500
        `
    else if (props?.color === "sky")
        className2 += `
            border-2 border-solid 
            text-gray-100 bg-sky-600 border-sky-600 
            hover:text-white hover:bg-sky-700 hover:border-sky-700 
            focus:text-white focus:bg-sky-700 focus:border-sky-800 
            dark:text-gray-100 dark:bg-sky-700 dark:border-sky-700
            dark:hover:text-white dark:hover:bg-sky-600 dark:hover:border-sky-600 
            dark:focus:text-white dark:focus:bg-sky-600 dark:focus:border-sky-500 
        `
    className2 += (" " + props?.className ?? " ")

    return (props?.to === undefined)
        ? <button {...props} className={className2}>{children}</button>
        : <Link {...props} className={className2}>{children}</Link>
}

export default EdButton