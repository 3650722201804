export const dataAcademicDegree = [
    {
        id: "associateDegree",
        en: { name: "Associate Degree" },
        fr: { name: "Diplôme d'associé" }
    },
    {
        id: "bachelorsDegree",
        en: { name: "Bachelor's Degree" },
        fr: { name: "Licence" }
    },
    {
        id: "mastersDegree",
        en: { name: "Master's Degree" },
        fr: { name: "Master" }
    },
    {
        id: "doctoralDegree",
        en: { name: "Doctoral Degree" },
        fr: { name: "Doctorat" }
    },
    {
        id: "professionalDegree",
        en: { name: "Professional Degree" },
        fr: { name: "Diplôme professionnel" }
    },
    {
        id: "certificateProgram",
        en: { name: "Certificate Program" },
        fr: { name: "Programme de certificat" }
    },
    {
        id: "diplomaProgram",
        en: { name: "Diploma Program" },
        fr: { name: "Programme de diplôme" }
    },
    {
        id: "nonDegreeProgram",
        en: { name: "Non-degree Program" },
        fr: { name: "Programme sans diplôme" }
    },
    {
        id: "other",
        en: { name: "Other" },
        fr: { name: "Autre" }
    }
];
