import { exceptionHelper } from './../helper/exceptionHelper';

const save = async (dataset) => {
    try {
        console.log("save apiTest-> dataset", dataset)
    } catch (ex) {
        exceptionHelper.handleException(ex);
    }
}


export const apiTest = {
    save,
}