import React from "react";
import BlockChart from './../../components/blockChart';

const PageDashboard = () =>
    <React.Fragment>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-0 mb-4">
            <section className="bi-card mt-6 mx-3" >
                <div className="flex items-center justify-between mb-4">
                    <div className="flex-shrink-0">
                        <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900 dark:text-gray-100">14.28%</span>
                        <h3 className="text-base font-normal text-gray-500">Overall Performance Rate</h3>
                    </div>
                    <div className="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                        <span className="mr-1">34.4%</span>
                        <i className="fa-solid fa-up-long"></i>
                    </div>
                </div>
                <div className="h-5/6">
                    <BlockChart />
                </div>
            </section>
            <section className="bi-card mt-6 mx-3">
                <div className="mb-4 flex items-center justify-between">
                    <div>
                        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-2">Latest Tests</h3>
                        <span className="text-base font-normal text-gray-500">Recent AI-Generated Test List</span>
                    </div>
                    <div className="flex-shrink-0">
                        <a href="/dashboard/tests" className="text-sm font-medium text-sky-600 hover:bg-gray-100 rounded-lg p-2">
                            View all
                        </a>
                    </div>
                </div>
                <div className="flex flex-col mt-8">
                    <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                            <div className="shadow overflow-hidden sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50 dark:bg-gray-700">
                                        <tr>
                                            {["Test Name", "Date", "Questions", "Status"].map((item, index) =>
                                                <th key={index} scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    {item}
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {[
                                            { testName: 'Market Analysis Mastery Test', date: 'Jan 3, 2023', questions: 50, status: 'Completed' },
                                            { testName: 'Competitive Positioning Exam', date: 'Feb 14, 2023', questions: 45, status: 'Completed' },
                                            { testName: 'Strategic Leadership Assessment', date: 'Mar 28, 2023', questions: 50, status: 'In Progress' },
                                            { testName: 'Business Growth Strategies Quiz', date: 'Apr 12, 2023', questions: 40, status: 'Canceled' },
                                            { testName: 'Financial Decision Making Test', date: 'May 24, 2023', questions: 45, status: 'Scheduled' },
                                            { testName: 'Innovation and Entrepreneurship Exam', date: 'Jun 7, 2023', questions: 50, status: 'Scheduled' },
                                            { testName: 'Global Business Environment Assessment', date: 'Jul 19, 2023', questions: 50, status: 'Scheduled' },
                                            { testName: 'Organizational Change Management Quiz', date: 'Aug 2, 2023', questions: 40, status: 'Scheduled' },
                                            { testName: 'Mergers and Acquisitions Test', date: 'Sep 13, 2023', questions: 45, status: 'Scheduled' },
                                            { testName: 'Corporate Governance and Ethics Exam', date: 'Oct 25, 2023', questions: 50, status: 'Scheduled' },
                                        ].map((row, rowIndex) =>
                                            <tr key={rowIndex}>
                                                {["testName", "date", "status", "questions"].map((col, colIndex) =>
                                                    <td key={colIndex} className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                                                        {/* 4 colors */}
                                                        {col === "status"
                                                            ? <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${row[col] === 'Completed' ? 'bg-green-100 text-green-800' : row[col] === 'In Progress' ? 'bg-yellow-100 text-yellow-800' : row[col] === 'Canceled' ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'}`}>{row[col]}</span>
                                                            : row[col]}
                                                    </td>
                                                )}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-0 mb-4">
            {[
                { name: 'Average Improvement Rate', percentage: '60%', rate: '+3%', color: 'green', icon: 'fa-up-long' },
                { name: 'Quiz Completion Percentage', percentage: '85%', rate: '+1%', color: 'green', icon: 'fa-up-long' },
                { name: 'Question Difficulty Index', percentage: '70%', rate: '+2%', color: 'green', icon: 'fa-up-long' },
            ]
                .map((item, index) =>
                    <div key={index} className="bi-card mx-3">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900 dark:text-gray-100">{item.percentage}</span>
                                <h3 className="text-base font-normal text-gray-500">{item.name}</h3>
                            </div>
                            <div className={`ml-5 w-0 flex items-center justify-end flex-1 text-${item.color}-500 text-base font-bold`}>
                                <span className="mr-1">{item.rate}</span>
                                <i className={`fa-solid ${item.icon}`}></i>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <section className="bi-card mx-3">
                <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-gray-100">Student Attendance Rate</h3>
                    <a href="/dashboard/insights" className="text-sm font-medium text-sky-600 hover:bg-gray-100 rounded-lg inline-flex items-center p-2">
                        View all
                    </a>
                </div>
                <div className="flow-root">
                    <div role="list" className="divide-y divide-gray-200">
                        {[
                            { studentName: 'John Doe', attendanceRate: '95%', email: 'john.doe@university.com' },
                            { studentName: 'Jane Doe', attendanceRate: '92%', email: 'jane.doe@university.com' },
                            { studentName: 'Jim Smith', attendanceRate: '92%', email: 'jim.smith@university.com' },
                            { studentName: 'Alice Johnson', attendanceRate: '89%', email: 'alice.johnson@university.com' },
                            { studentName: 'Bob Williams', attendanceRate: '88%', email: 'bob.williams@university.com' }
                        ].map((item, index) =>
                            <div key={index} className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                        <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center text-gray-500 font-bold">
                                            {item.studentName.split(" ").map((word, index) => index < 2 ? word[0] : null)}
                                        </div>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <div className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                                            {item.studentName}
                                        </div>
                                        <div className="text-sm text-gray-500 truncate">
                                            {item.email}
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-gray-100 ">
                                        {item.attendanceRate}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="bi-card mx-3">
                <h3 className="text-xl leading-none font-bold text-gray-900 dark:text-gray-100 mb-10">Classwise Performance Metrics</h3>
                <div className="block w-full overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                {["Course Title", "Enrollment Count", "Success Rate"].map((item, index) =>
                                    <th key={index} className="px-4 bg-gray-50 text-gray-700 dark:text-gray-300 dark:bg-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">
                                        {item}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100">
                            {[
                                { courseTitle: 'Strategic Management', enrollmentCount: 35, successRate: 85 },
                                { courseTitle: 'Financial Analysis and Decision Making', enrollmentCount: 42, successRate: 58 },
                                { courseTitle: 'Market Research and Consumer Behavior', enrollmentCount: 28, successRate: 92 },
                                { courseTitle: 'Leadership and Change Management', enrollmentCount: 39, successRate: 70 },
                                { courseTitle: 'Supply Chain Management', enrollmentCount: 37, successRate: 87 },
                                { courseTitle: 'Entrepreneurship and Innovation', enrollmentCount: 31, successRate: 61 }
                            ].map((row, rowIndex) =>
                                <tr className="text-gray-500" key={rowIndex}>
                                    <th className="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">{row.courseTitle}</th>
                                    <td className="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 dark:text-gray-100 whitespace-nowrap p-4">{row.enrollmentCount}</td>
                                    <td className="border-t-0 px-4 align-middle text-xs whitespace-nowrap p-4">
                                        <div className="flex items-center">
                                            <span className="mr-2 text-xs font-medium">{row.successRate}%</span>
                                            <div className="relative w-full">
                                                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-sm h-2">
                                                    <div className={`${row.successRate > 80 ? 'bg-emerald-300' : row.successRate > 65 ? 'bg-amber-300' : 'bg-red-300'} h-2 rounded-sm`} style={{ width: `${row.successRate}%` }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </React.Fragment>


export default PageDashboard;







