import React from "react";
import { Outlet, Link } from "react-router-dom";
import BlockCopyright from './../../components/blockCopyright';
import { ContextTheme } from './../contextTheme';
import BlockDarkSwitch from './../../components/blockDarkSwitch';

const LayoutDashboard = () => {
    const { theme } = React.useContext(ContextTheme);

    const showIcon = (icon) => {
        const defaultClassNames = "text-gray-500 flex-shrink-0 group-hover:text-gray-900 dark:group-hover:text-gray-100 transition duration-200"
        switch (icon) {
            case "dashboard":
                return <svg className={defaultClassNames} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
            case "gem-dark":
                return <svg className={defaultClassNames} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path></svg>
            case "gem-gold":
                return <svg className={defaultClassNames + " text-sky-600"} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path></svg>
            case "gem-white":
                return <svg className="svg-inline--fa fa-gem -ml-1 mr-2 w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path></svg>
            case "document":
                return <svg className={defaultClassNames} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path></svg>
            default:
                return <i className={defaultClassNames + ` ${icon} scale-110 pb-5`}></i>
        }
    }

    const showNavItem = (label, path, target, icon, badge) =>
        <a href={path} target={target} className="text-base font-normal rounded-lg flex items-center p-2 group no-underline hover:no-underline text-gray-900  hover:bg-sky-100  dark:text-gray-100 dark:hover:bg-sky-700 ">
            <span className="w-5 h-5 text-center">
                {showIcon(icon)}
            </span>
            <span className="ml-3 flex-1 whitespace-nowrap ">{label}</span>
            {badge && <span className="bg-gray-200 text-gray-800 ml-3 text-sm font-medium inline-flex items-center justify-center px-2 rounded-full">{badge}</span>}
        </a>


    return <React.Fragment>
        {/* Dahsbord Header */}
        <header className="border-b border-gray-200 fixed z-30 w-full bg-white dark:bg-gray-800 shadow-sm dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        {/* Humburger Menu */}
                        <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">
                            <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg id="toggleSidebarMobileClose" className="w-6 h-6 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                        {/* Logo2 */}
                        <a href="/">
                            <img src={`/logo2-${theme || "light"}.png`} className="h-12 mx-2" alt="EdPLATO - Adaptive Testing & Personalized Learning" />
                        </a>
                        {/* Search */}
                        <form action="#" method="GET" className="hidden lg:block lg:pl-24">
                            <label htmlFor="topbar-search" className="sr-only">Search</label>
                            <div className="mt-1 relative lg:w-64">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg className="text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <input type="text" name="email" id="topbar-search"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-sky-600 focus:border-sky-600 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100 dark:focus:ring-sky-600 dark:focus:border-sky-600 dark:placeholder-gray-500 dark:placeholder-opacity-75 dark:ring-opacity-50 dark:ring-sky-600 dark:ring-offset-sky-600 dark:ring-offset-2 dark:ring-opacity-50 dark:ring-offset-opacity-50"
                                    placeholder="Search" />
                            </div>
                        </form>
                    </div>
                    <div className="flex items-center ">
                        {/* Notification Button */}
                        <a href="#notifications" type="button" className="text-gray-500 hover:text-gray-900 hover:bg-gray-100 py-2 px-3 rounded-lg">
                            <span className="sr-only">Notifications</span>
                            <i className="fas fa-bell"></i>
                        </a>
                        {/* Generate Test*/}
                        <a href="/dashboard/generate-test-demo/" className="hidden sm:inline-flex mx-3 text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 focus:ring-sky-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center no-underline hover:no-underline">
                            {showIcon("gem-white")}
                            Generate Test Demo
                        </a>
                        <BlockDarkSwitch />
                    </div>
                </div>
            </div>
        </header>

        <div className="flex overflow-hidden pt-16 dark:bg-gray-800 h-screen">
            {/* Side Menu */}
            <aside id="sidebar" className="fixed hidden z-20 h-full top-0 left-0 pt-16 lg:flex flex-shrink-0 flex-col w-64 transition-width duration-200" aria-label="Sidebar">
                <div className="relative flex-1 flex flex-col min-h-0 border-r pt-0 border-gray-200 dark:border-gray-700">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex-1 px-3 divide-y space-y-1">
                            <ul className="list-none m-0 p-0 space-y-2 pb-2">
                                <li>
                                    <form action="#" method="GET" className="lg:hidden">
                                        <label htmlFor="mobile-search" className="sr-only">Search</label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                                            </div>
                                            <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-sky-600 block w-full pl-10 p-2.5" placeholder="Search" />
                                        </div>
                                    </form>
                                </li>
                                <li> {showNavItem("Dashboard", "/dashboard", "_self", "dashboard", "")} </li>
                                <li> {showNavItem("Courses", "/dashboard/courses", "_self", "fa-solid fa-book", "")} </li>
                                <li> {showNavItem("Tests", "/dashboard/tests", "_self", "fas fa-clipboard-check", "")} </li>
                                <li> {showNavItem("Generate Test", "/dashboard/generate-test", "_self", "gem-gold", "")} </li>
                                <li> {showNavItem("Generate Test Demo", "/dashboard/generate-test-demo", "_self", "gem-dark", "")} </li>
                                <li> {showNavItem("Insights", "/dashboard/insights", "_self", "fa-solid fa-chart-pie", "Pro")} </li>
                                <li> {showNavItem("Profile", "/dashboard/profile", "_self", "fa-solid fa-user", "")} </li>
                                <li> {showNavItem("Settings", "/dashboard/settings", "_self", "fa-solid fa-cog", "")} </li>
                            </ul>
                            <div className="space-y-2 pt-2">
                                {showNavItem("Upgrade to Pro", "/pricing#pro", "_blank", "gem-dark", "")}
                                {showNavItem("Documentation", "/documentation", "_blank", "document", "")}
                                {showNavItem("Help", "/help", "_blank", "fa-solid fa-life-ring", "")}
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
            <div className="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>

            {/* Main & Footer */}
            <div className="h-full w-full relative overflow-y-auto lg:ml-64">
                <Outlet />
                <footer className="md:flex md:items-center md:justify-between shadow rounded-lg p-2 md:p-3 xl:p-4 my-6 mx-4">
                    <ul className="list-none p-0 m-0 flex items-center flex-wrap mb-6 md:mb-0">
                        {[
                            { name: "Terms & conditions", link: "/terms" },
                            { name: "Privacy Policy", link: "/privacy" },
                            { name: "Licensing", link: "/licensing" },
                            { name: "Cookie Policy", link: "/policy" },
                            { name: "Contact", link: "/contact" },
                        ].map((item, index) =>
                            <li className="m-0" key={index}>
                                <Link to={item.link} className="footer-item text-sm mr-4 lg:mr-6">{item.name}</Link>
                            </li>
                        )}
                    </ul>
                    <div className="flex sm:justify-center space-x-6">
                        {[
                            { name: "Facebook", icon: "fab fa-facebook-f", link: "https://www.facebook.com/edplato.official" },
                            { name: "Instagram", icon: "fab fa-instagram", link: "https://www.instagram.com/edplato_com" },
                            { name: "Twitter", icon: "fab fa-twitter", link: "https://twitter.com/edplato_com" },
                            { name: "LinkedIn", icon: "fab fa-linkedin-in", link: "https://www.linkedin.com/company/edplato-com" },
                            { name: "YouTube", icon: "fab fa-youtube", link: "https://www.youtube.com/@EdPLATO" },
                        ].map((item, index) =>
                            <a href={item.link} key={index} className="footer-item" target="_blank" rel="noreferrer">
                                <i className={item.icon}></i>
                                <span className="sr-only">{item.name}</span>
                            </a>
                        )}
                    </div>
                </footer>
                <BlockCopyright />
            </div>
        </div>
    </React.Fragment>
}

export default LayoutDashboard