import React from 'react';
import { Table, Dropdown, Breadcrumb, Tooltip, Button, Pagination, Modal } from 'flowbite-react';
import { dataCourses } from './../../data/dataCourses';
import BlockFilterBox from './../../components/blockFilterBox';
import { getCurrentLang } from '../../helper/languageHelper';


const PageCourses = () => {
    //const [dataset, setDataset] = React.useState([]);
    const [modalPOC, setModalPOC] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState(false);
    const lang = getCurrentLang();

    // React.useEffect(() => {
    //     apiCourses.getAll()
    //         .then(data => setDataset(data))
    //         .catch(ex => exceptionHelper.handleException(ex));
    // }, []);


    return <main className="dashboard p-8 pb-24">

        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Courses</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className="text-3xl mb-0">Courses</h1>
        <p className="text-lg mb-6">View list of your courses.</p>

        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">

            {/* Table Toolbar */}
            <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                <BlockFilterBox />

                {/* Right Side */}
                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                    <Button type="button" size="sm" color='light' onClick={() => window.location.href = `/dashboard/courses/new`}>New Course<i className="ml-2 fas fa-plus"></i></Button>
                </div>
            </div>

            {/* {console.log("PageCourses -> dataset", dataset)} */}

            {/* Table */}
            <div className="overflow-x-auto px-4">
                <div className="relative">
                    <Table className="border border-1">
                        <Table.Head>
                            <Table.HeadCell>Created On</Table.HeadCell>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Source</Table.HeadCell>
                            <Table.HeadCell>Sessions / Materials</Table.HeadCell>
                            <Table.HeadCell><span className="sr-only">Action</span>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {dataCourses.map((item, index) =>
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {item.createdOn}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a href={`/dashboard/courses/${item.id}`} className="font-medium text-gray-900 dark:text-white">{item[lang].name}</a>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div className="flex items-center">
                                            <img src={`/images/logo-${item.source}.png`} alt={item.source} className="w-6 mr-2" />
                                            <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                {item.source}
                                            </span>
                                            {item.isModified &&
                                                <Tooltip content="Modified">
                                                    <i className="ml-2 fas fa-exclamation-circle text-gray-400 dark:text-gray-300 cursor-pointer" />
                                                </Tooltip>
                                            }
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item[lang].sessions.length} / {item[lang].sessions.reduce((acc, cur) => acc + cur.materials.length, 0)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Dropdown
                                            renderTrigger={() => (
                                                <div className='cursor-pointer text-gray-400 hover:text-gray-600 dark:hover:text-gray-300'>
                                                    <i className="fas fa-ellipsis-h fa-lg"></i>
                                                </div>
                                            )}>
                                            <Dropdown.Item onClick={() => window.location.href = `/dashboard/courses/${item.id}`} >View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setModalDelete(true)} className="text-red-500">Delete</Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => window.location.href = `/dashboard/generate-test?course=${item.id}`}>Generate Test</Dropdown.Item>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
            </div>

            {/* Table Footer */}
            <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    Showing
                    <span className="mx-2 font-semibold text-gray-900 dark:text-white">1-7</span>
                    of
                    <span className="mx-2 font-semibold text-gray-900 dark:text-white">33</span>
                </span>
                <Pagination
                    className="paggination-sky"
                    currentPage={1}
                    onPageChange={(page) => { /*setCurrentPage(page)*/ }}
                    showIcons
                    totalPages={100}
                />
            </nav>
        </div>

        <Modal className='dashboard' show={modalPOC === true} onClose={() => setModalPOC(false)}>
            <Modal.Header>PoC Version</Modal.Header>
            <Modal.Body>
                The current version of our software is still in development, and we are working on adding new features. We apologize for any inconvenience caused by the incomplete state of the software. Once the software is complete, you will have access to the feature you mentioned, as well as many other similar features. Thank you for your understanding and patience.
            </Modal.Body>
            <Modal.Footer>
                <Button className="sky" onClick={() => setModalPOC(false)}>Close</Button>
            </Modal.Footer>
        </Modal>


        <Modal className='dashboard' show={modalDelete === true} size="md" popup onClose={() => setModalDelete(false)}>
            <Modal.Header />
            <Modal.Body>
                <div className="text-center">
                    <span className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-200">
                        <i className="fas fa-trash fa-3x"></i>
                    </span>
                    <h3 className="my-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Are you sure you want to delete this course and all related data?
                    </h3>
                    <div className="flex justify-center gap-4">
                        <Button type="button" color="success" onClick={() => setModalDelete(false)}>Yes, I'm sure</Button>
                        <Button type="button" color="light" onClick={() => setModalDelete(false)}>No, cancel</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    </main>
}

export default PageCourses
