import React from "react";
import { Disclosure } from "@headlessui/react";

const BlockFAQ = ({ title, description, items }) =>
    <section className="max-w-none w-full mx-auto !p-0 dark:bg-gray-800">
        <div className="flex w-full flex-col mt-4 items-center justify-center text-center">
            <h2 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight lg:text-4xl dark:text-white">{title}</h2>
            <p className="max-w-2xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300">{description}</p>
        </div>
        <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
            {items.map((item, index) => (
                <div className="mb-5" key={index}>
                    <Disclosure>
                        {({ open }) => (
                            <React.Fragment>
                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-4 text-lg text-left rounded-lg bg-gray-100 hover:bg-gray-100 text-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-sky-100 focus-visible:ring-opacity-75 dark:bg-gray-700 dark:text-gray-200">
                                    <span>{item.question}</span>
                                    <i className={`fa-solid ${open ? "fa-chevron-down" : "fa-chevron-up"} w-5 h-5 text-gray-800`} />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300">
                                    {item.answer}
                                </Disclosure.Panel>
                            </React.Fragment>
                        )}
                    </Disclosure>
                </div>
            ))}
        </div>
    </section>


export default BlockFAQ