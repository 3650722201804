import React from 'react';
import { Breadcrumb, Spinner } from 'flowbite-react';
import BlockWizard from '../../components/blockWizard';
import { apiCourses } from '../../api/apiCourses';
import { exceptionHelper } from './../../helper/exceptionHelper';
import { useSearchParams } from "react-router-dom";
import EdNumber from '../../components/edNumber';
import EdToggle from './../../components/edToggle';
import EdButton from './../../components/edButton';
import { showWarning } from '../../helper/swalHelper';
import { apiTest } from '../../api/apiTest';
import EdTooltipInfo from './../../components/edTooltipInfo';


const PageGenerateTest = () => {
    const [wizardIndex, setWizardIndex] = React.useState(0);
    const [courses, setCourses] = React.useState([]);
    const [searchParams] = useSearchParams();
    const [dataset, setDataset] = React.useState({
        courseSelection: searchParams?.get("course") ?? undefined,
        testName: "Untitled Test",
        difficultyLevel: 3,
        questionCount: 10,
        questionTypes: "multipleChoice",
        randomizeQuestions: true,
        inclusionOfMedia: false,
        testDuration: 60,
        scoringSystem: false,
        specialInstructions: "",
    })

    const handleChangeNumber = (e, name, value) => {
        e?.preventDefault();
        setDataset({ ...dataset, [name]: parseInt(value) });
    }

    const handleChangeCheckbox = (e) => {
        e.preventDefault();
        setDataset({ ...dataset, [e.target.name]: e.target.checked });
    }

    const handleChangeValue = (e) => {
        e.preventDefault();
        setDataset({ ...dataset, [e.target.name]: e.target.value });
    }

    React.useEffect(() => {
        apiCourses.getCourses()
            .then((res) => { setCourses(res); })
            .catch((ex) => { exceptionHelper.handleException(ex); });
    }, []);

    const handleCancel = () => {
        showWarning({
            title: "Are you sure you want to cancel?",
            text: "All changes will be lost.",
            confirmButtonText: "Yes, cancel it!",
            cancelButtonText: "No, keep it",
        })
            .then((result) => {
                if (result.isConfirmed) { window.location.href = "/dashboard"; }
            })
    }

    const handleNext = () => {
        apiTest.save(dataset);
        setWizardIndex(1);
    }

    const showPreferences = () =>
        <div className="w-screen md:w-auto flex flex-col items-start justify-start rounded-lg space-y-4">
            {/* Basic Information: */}
            <div className="text-lg font-semibold w-full pt-12">Basic Information</div>
            <div className="flex items-center w-full">
                <label htmlFor="courseSelection" className='mr-2'>Course Selection <EdTooltipInfo>Select the course for which you want to generate a test.</EdTooltipInfo>:</label>
                <select className="edInput flex-grow" name="courseSelection" defaultValue={dataset.courseSelection} onChange={handleChangeValue}>
                    {courses.map((item) =>
                        <option value={item.id} key={item.id}>
                            {item.name}
                        </option>)}
                </select>
            </div>
            <div className="flex items-center w-full">
                <label htmlFor="testName" className='mr-2'>Test Name:</label>
                <input type="text" name="testName" className="edInput flex-grow" defaultValue={dataset.testName} onChange={handleChangeValue} />
            </div>
            <div className="flex items-center w-full flex-row">
                <div className='grow pe-6'>
                    <label htmlFor="difficultyLevel" className='mr-2'>Difficulty Level ({dataset.difficultyLevel}/10):</label>
                    <input id="difficultyLevel"
                        name="difficultyLevel"
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                        type="range"
                        min="1" max="10" step="1"
                        defaultValue={dataset.difficultyLevel}
                        onChange={e => handleChangeNumber(e, "difficultyLevel", e.target.value)}
                    />
                    <span className="flex justify-between text-xs text-gray-500 dark:text-gray-400">
                        <small>Beginner</small>
                        <small>Intermediate</small>
                        <small>Advanced</small>
                    </span>
                </div>
                <div className='w-40'>
                    <label htmlFor="questionCount" className='mr-2'>Question Count <EdTooltipInfo>Define the total number of questions for the test</EdTooltipInfo>:</label>
                    <EdNumber
                        className="mt-1"
                        name="questionCount"
                        value={dataset.questionCount}
                        onChange={e => handleChangeNumber(e, "questionCount", e.target.value)}
                        onIncrement={e => handleChangeNumber(e, "questionCount", parseInt(dataset.questionCount) + 1)}
                        onDecrement={e => handleChangeNumber(e, "questionCount", parseInt(dataset.questionCount) - 1)}
                    />
                </div>
            </div>
            {/* Question Configuration: */}
            <div className="text-lg font-semibold w-full pt-12">Question Configuration</div>
            <div className="flex items-center w-full">
                <label htmlFor="questionTypes" className='mr-2'>Question Types:</label>
                <select className="edInput flex-grow" name="questionTypes" defaultValue={dataset.questionTypes} onChange={handleChangeValue}>
                    <option value="multipleChoice">Multiple Choice</option>
                    <option value="trueFalse">True/False</option>
                    <option value="fillInTheBlank">Fill in the Blank</option>
                    <option value="matching">Matching</option>
                    <option value="shortAnswer">Short Answer</option>
                    <option value="essay">Essay</option>
                    <option value="problemSolving">Problem Solving</option>
                </select>
            </div>
            <div className="flex items-center w-full">
                <div className="flex items-center w-full flex-row">
                    <div className='pe-6'>
                        <label htmlFor="randomizeQuestions" className='mr-2'>Randomize Questions <EdTooltipInfo>Option to shuffle questions for each test attempt.</EdTooltipInfo>:</label>
                        <EdToggle name="randomizeQuestions" onChange={handleChangeCheckbox} defaultValue={dataset.randomizeQuestions} />
                    </div>
                    <div className='pe-6'>
                        <label htmlFor="inclusionOfMedia" className='mr-2'>Inclusion of Media <EdTooltipInfo>Decide if questions should contain images, videos, or audio.</EdTooltipInfo>:</label>
                        <EdToggle name="inclusionOfMedia" onChange={handleChangeCheckbox} defaultValue={dataset.inclusionOfMedia} />
                    </div>
                </div>
            </div>

            {/* Test Management: */}
            <div className="text-lg font-semibold w-full pt-12">Test Management</div>
            <div className="flex items-center w-full flex-row">
                <div className='flex flex-row items-center pe-6'>
                    <label htmlFor="testDuration" className='mr-2'>Test Duration <EdTooltipInfo>Set a time limit for completing the test.</EdTooltipInfo>:</label>
                    <EdNumber
                        className="w-32"
                        name="testDuration"
                        value={dataset.testDuration}
                        onChange={e => handleChangeNumber}
                        onIncrement={e => handleChangeNumber(e, "testDuration", parseInt(dataset.testDuration))}
                        onDecrement={e => handleChangeNumber(e, "testDuration", parseInt(dataset.testDuration))}
                    />
                </div>
                <div className=''>
                    <label htmlFor="scoringSystem" className='mr-2'>Scoring System <EdTooltipInfo>Allocate points for different types of questions.</EdTooltipInfo>:</label>
                    <EdToggle name="scoringSystem" onChange={handleChangeCheckbox} defaultValue={dataset.scoringSystem} />
                </div>
            </div>
            <div className="flex items-center w-full">
                <textarea
                    className="edInput flex-grow"
                    name="specialInstructions"
                    onChange={handleChangeValue}
                    placeholder="Special Instructions"
                    rows="4"
                />
            </div>

            {/* Buttons */}
            <div className="flex flex-row items-center w-full pt-6 pb-12 space-x-4">
                <EdButton color="white" className="ml-auto" onClick={handleCancel}>Cancel</EdButton>
                <EdButton color="sky" onClick={handleNext}>Save & Generate Test</EdButton>
            </div>
        </div>

    const showAutoGenerate = () => {
        return <></>
    }

    const showReview = () => {
        return <></>
    }

    return <main className="dashboard p-8 pt-12">

        {/* 1- Breadcrumb */}
        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Generate Test</Breadcrumb.Item>
        </Breadcrumb>
        {console.log(dataset)}
        {/* 2- header */}
        <h1 className="text-3xl mb-0">Generate Test</h1>
        <p className="text-lg mb-6">Generate test for a course.</p>

        {/* 3- Wizard */}
        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">
            <div className="flex items-center justify-start w-full h-full border-b border-gray-100 dark:border-gray-700 py-12 px-4 md:px-28">
                <BlockWizard
                    dataset={[
                        { title: "Test Preferences", description: "Define your quiz criteria." },
                        { title: "Auto-Generate Quiz", description: "Let AI create your test." },
                        { title: "Review & Publish", description: "Finalize and release your quiz." }
                    ]}
                    selectedIndex={wizardIndex}
                />
            </div>

            {(courses && courses.length > 0)
                ? <div className="flex flex-col items-center justify-start w-full h-full min-h-screen">
                    {wizardIndex === 0 && showPreferences()}
                    {wizardIndex === 1 && showAutoGenerate()}
                    {wizardIndex === 2 && showReview()}
                </div>
                : <div className="text-center p-12">
                    <Spinner className="m-8" />
                </div>
            }
        </div>

        <iframe id="printableFrame" style={{ height: "0px", width: "0px", position: "absolute" }} title="print"></iframe>
    </main>
}

export default PageGenerateTest